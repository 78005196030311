import { useTranslate } from 'react-admin';

import polyglotI18nProvider from 'ra-i18n-polyglot';

/*import englishMessages from 'ra-language-english';
import catalanMessages from '@joshfradley/ra-language-catalan';*/

var catalanMessages = {
  ra: {
    action: {
      add_filter: 'Afegir filtre',
      add: 'Afegir',
      back: 'Tornar',
      bulk_actions: '1 element seleccionat |||| %{smart_count} elements seleccionats',
      cancel: 'Cancel·lar',
      clear_input_value: 'Netejar valor',
      clone: 'Clonar',
      create: 'Afegir',
      create_item: 'Afegir', /* %{item} */
      delete: 'Esborrar',
      edit: 'Modificar',
      export: 'Exportar',
      list: 'Llistar',
      refresh: 'Refrescar',
      remove_filter: 'Esborrar aquest filtre',
      remove: 'Esborrar',
      save: 'Desar',
      search: 'Cercar',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Desfer',
      confirm: 'Confirmeu',
      unselect: 'Desseleccionar',
      expand: 'Ampliar',
      close: 'Tanca',
      open_menu: 'Obre el menú',
      close_menu: 'Tanca el menú',
    },
    boolean: {
      true: 'Si',
      false: 'No',
      null: ' ',
    },
    page: {
      create: 'Afegir', /* %{name} */
      dashboard: 'Inici',
      edit: '%{name} #%{id}',
      error: 'S\'ha produït un error',
      list: 'Llista de %{name}',
      loading: 'Carregant...',
      not_found: 'No s\'ha trobat',
      show: '%{name} #%{id}',
      empty: 'Encara no hi ha %{name}.',
      invite: '¿Vols afegir-ne una?',
    },
    input: {
      file: {
        upload_several: 'Arrossega els fitxers a pujar o clica per seleccionar-ne un.',
        upload_single: 'Arrossega el fitxer a pujar o clica per seleccionar-lo.',
      },
      image: {
        upload_several: 'Arrossega les imatges a pujar o clica per seleccionar-ne una.',
        upload_single: 'Arrossega la imatge a pujar o clica per seleccionar-la.',
      },
      references: {
        all_missing: 'No s\'han pogut trobar referències.',
        many_missing: 'Almenys una de les referències associades no sembla estar disponible.',
        single_missing: 'La referència associada ja no està disponible.',
      },
      password: {
        toggle_visible: 'Amagueu la contrasenya',
        toggle_hidden: 'Mostrar contrasenya',
      },
    },
    message: {
      about: 'Sobre',
      are_you_sure: 'Està segur?',
      bulk_delete_content: 'Està segur d\'esborrar l\'element %{name}? |||| Està segur d\'esborrar aquests %{smart_count} elements?',
      bulk_delete_title: 'Esborrar %{name} |||| Esborrar %{smart_count} %{name} elements',
      delete_content: 'Està segur d\'esborrar-lo?',
      delete_title: 'Esborrar registre' , /* %{name} #%{id}" */
      details: 'Detalls',
      error: 'S\'ha prodït un error al client i la seva petició no s\'ha pogut completar.',
      invalid_form: 'El formulari no és vàlid. Si us plau, revisa els errors',
      loading: 'La pàgina s\'està carregant, esperi un moment si us plau',
      no: 'No',
      not_found: 'O bé ha escrit una URL incorrecta, o bé ha navegat a un enllaç erroni.',
      yes: 'Sí',
      unsaved_changes: 'Alguns dels canvis no s\'han desat. Esteu segur que voleu ignorar-les?',
    },
    navigation: {
      no_results: 'No hi ha registres',
      no_more_results: 'La pàgina número %{page} està fora de rang. Provi amb la pàgina anterior.',
      page_out_of_boundaries: 'Pàgina número %{page} fora de rang',
      page_out_from_end: 'No es pot anar més enllà de la última pàgina',
      page_out_from_begin: 'No es pot anar més enllà de la pàgina 1',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Files per pàgina:',
      next: 'Següent',
      prev: 'Anterior',
      skip_nav: 'Ves al contingut',
    },
    sort: {
      sort_by: 'Ordenar per %{field} %{order}',
      ASC: 'ascendent',
      DESC: 'descendent',
    },
    auth: {
      user_menu: 'Perfil',
      username: 'Usuari',
      password: 'Contrasenya',
      sign_in: 'Inicia sessió',
      sign_in_error: 'Ha fallat l\'autenticació, si us plau torni a provar-ho',
      logout: 'Tancar sessió',
      auth_check_error: 'Inicieu la sessió per continuar',
      error: 'Usuari o contrasenya incorrecta'
    },
    notification: {
      updated: 'Registre actualitzat |||| %{smart_count} registres actualitzats',
      created: 'Registre creat',
      deleted: 'Registre eliminat |||| %{smart_count} registres eliminats',
      bad_item: 'Element incorrecte',
      logged_out: 'La seva sessió ha finalitzat, torneu a connectar-se.',
      item_doesnt_exist: 'L\'element no existeix',
      http_error: 'Error de comunicació amb el servidor',
      data_provider_error: 'Error del dataProvider. Comprova la consola per més detalls.',
      canceled: 'Acció cancel·lada',
      i18n_error: 'No es poden carregar les traduccions per a l\'idioma especificat',
    },
    validation: {
      required: 'Obligatori',
      minLength: 'Longitud mínima de %{min} caràcters',
      maxLength: 'Màxim %{max} caràcters',
      minValue: 'El valor mínim és %{min}',
      maxValue: 'El valor màxim és %{max}',
      number: 'Número invàlid',
      email: 'Ha de ser una adreça electrònica vàlida',
      oneOf: 'Ha de ser un d\'aquests valors: %{options}',
      regex: 'Ha de complir el format (expressió regular): %{pattern}', 
    }
  },


  common : {
    id : 'Id',
    name : 'Nom',
    name_internal : 'Nom intern',
    description : 'Descripció',
    email : 'Correu electrònic',
    image : 'Imatge',
    action : 'Acció',
    actions : 'Accions',
    search : 'Cercar',
    actived : 'Actiu',
    actived_female : 'Activa',
    edit : 'Editar',
    visible: 'Visible',
    priority: 'Ordre',
    comment: 'Comentari',
    loading: 'Carregant...',
    data: 'Dades',
    type: 'Tipus',
    status: 'Estat',
    month_1: 'Gen',
    month_2: 'Feb',
    month_3: 'Mar',
    month_4: 'Abr',
    month_5: 'Mai',
    month_6: 'Jun',
    month_7: 'Jul',
    month_8: 'Ago',
    month_9: 'Set',
    month_10: 'Oct',
    month_11: 'Nov',
    month_12: 'Des',     
    months_1: 'Gener',
    months_2: 'Febrer',
    months_3: 'Març',
    months_4: 'Abril',
    months_5: 'Maig',
    months_6: 'Juny',
    months_7: 'Juliol',
    months_8: 'Agost',
    months_9: 'Setembre',
    months_10: 'Octubre',
    months_11: 'Novembre',
    months_12: 'Desembre',    
    year : 'Any',
    notification : { 
      updated: 'Element actualitzat',
      error: 'S\'ha produït un error'
    },
    validation: {
      required: 'Obligatori',
      minLength: 'Longitud mínima de %{min} caràcters',
      maxLength: 'Màxim %{max} caràcters',
      minValue: 'El valor mínim és %{min}',
      maxValue: 'El valor màxim és %{max}',
      number: 'Número invàlid',
      email: 'El format del correu electrònic no és correcte',
      oneOf: 'Ha de ser un d\'aquests valors: %{options}',
      regex: 'Ha de complir el format (expressió regular): %{pattern}',
      minMaxLengthAndCharacters: '%{min}-%{max} caràcters, amb un número, lletra, majúscula, minúscula i caràcter especial',
    },
    yes: 'Sí',
    no: 'No' ,
    labels: 'Etiquetes',
    day_week_1: 'Dill',
    day_week_2: 'Dm',
    day_week_3: 'Dim',
    day_week_4: 'Dij',
    day_week_5: 'Div',
    day_week_6: 'Dis',
    day_week_7: 'Diu',
    day_week_complete_1: 'Dilluns',
    day_week_complete_2: 'Dimarts',
    day_week_complete_3: 'Dimecres',
    day_week_complete_4: 'Dijous',
    day_week_complete_5: 'Divendres',
    day_week_complete_6: 'Dissabte',
    day_week_complete_7: 'Diumenge',
    title: 'Títol',
    link: 'Enllaç',
    notFoundPage: 'La pàgina no existeix',
    goBack: 'Tornar enrere',
    date_from: 'Des del ',
    date_to: ' fins el ',
    time_to: 'A les ',
    download_ok: 'El fitxer s\'ha generat correctament. Consulta la carpeta de descàrregues del navegador',
        
  },
  main_menu : {
    home: 'Inici',
    groups: 'Grups',
    users: 'Contactes',
    recercat_users: 'Carnets',
    recercat_centers: 'Centres',
    docs_fac: 'Documents',
    remittances: 'Rebuts',
    classrooms: 'Espais',
    users_groups : 'Assignació a grup',
    associations: 'Clients',
    modules: 'Mòduls',
    modules_view: 'Més Apps...',
    real_state: 'Immobles',
    agreements: 'Convenis',
    insurances: 'Assegurances',
    permissions: 'Permisos',
    subscriptions: 'Subscripcions',
    options: 'Opcions',
    contacts: 'Contactes FAC',
    events: 'Agenda',
    reports: 'Informes',
  },
  dashboard : {
    members: 'Evolució de socis',  
    birthdays: 'Aniversaris',
    members_legend_female : 'Dones',
    members_legend_male : 'Homes',
    members_legend_no_binary : 'No binaris',
    members_legend_no_informed : 'No informat',
    members_legend_companies : 'Entitats jurídiques',
    members_view: 'Socis',
    members_view_requests: 'Sol·licituds web',
    members_add: 'Afegir',
    people: 'persones',
    age_sufix: 'anys',
    age_pyramid: 'Piràmide d\'edats',
    today: 'Activitats d\'avui',
    statistics: 'Estat de socis',
    statistics_total_members: 'Número de socis',
    statistics_avg_age: 'Mitjana d\'edat', 
    statistics_gender: 'Per gènere',
    last_members: 'Socis més recents',
    last_members_view: 'Veure tots',
    remittances: 'Rebuts',
    remittances_legend_accepted: 'Acceptats',
    remittances_legend_returned: 'Impagats',
    remittances_add: 'Crear una remesa',
    subscription_active_modules: "Mòduls actius",
    subscription_view_all_modules: "Afegir mòduls",
    subscription: 'Dades de la subscripció',
    subscription_name: 'Pla de servei',
    subscription_status: 'Estat',
    subscription_date_expiration: 'Data d\'expiració',
    subscription_users : 'contactes',        
    subscription_size : 'MB',
    subscription_change : 'Modificar',
    help: 'Suport a l\'usuari',
    help_manual_link: 'Manual d\'usuari',       
    help_description_sifac: 'Per a qualsevol consulta o suggeriment sobre l\'ús de l\'aplicatiu envia un correu electrònic a suport@sifac.cat o bé truca al telèfon 691957814 de dilluns a divendres de 9h a 13h i de 16h a 19h.',
    help_description: 'Per a qualsevol consulta o suggeriment sobre l\'ús de l\'aplicatiu envieu un correu electrònic a admin@gestioentitats.cat. Us respondrem el més aviat possible.',                
    federation_members: 'Número de socis',
    federation_total_people: 'Número de socis d\'entitats',
    federation_avg_age: 'Mitjana d\'edat',
    federation_gender: 'Per gènere',
    federation_map: 'Delegacions territorials',        
    federation_associations: 'entitats',
    alert_subscription_admin: 'Algunes funcions no estan operatives perquè no s\'ha detectat cap subscripció del Gestió d\'entitats activa. Si us plau, contacta amb l\'equip de suport.'
  },
  
  modules_view : {
    title: "Mòduls disponibles",
    actived: "Activat",   
    to_desactive: "Desactivar",   
    to_active_button: "Activar",    
    change_modules_title: "Activar/desactivar mòduls",
    to_activate: "Per a modificar els mòduls actius i/o el pla de servei contractat, poseu-vos en contacte per correu electrònic a admin@gestioentitats.cat. Us respondrem el més aviat possible.",
    to_activate_sifac: "Per a modificar els mòduls actius i/o el pla de servei contractat, poseu-vos en contacte per correu electrònic a suport@sifac.cat o bé truca al telèfon 691957814 de dilluns a divendres de 9h a 13h i de 16h a 19h.",    
  },
  
  reports: {
    title: 'Generar informe',  
    annuary: 'Anuari',        
    annuary_desc: 'Dades per a realitzar l\'anuari',
    annuary_item_id: 'Id',
    annuary_item_group: 'Àmbit',
    annuary_item_calc: 'Càlcul',
    annuary_item_name: 'Nom',
    annuary_item_value: 'Quantitat',
    annuary_item_percent: '%',
    annuary_item_avg: 'Mitjana',
    annuary_exporter_file_name: 'anuari',
    annuary_last_years: 'Generar l\'informe amb dades d\'anys anteriors si no existeixen dades per l\'any seleccionat',
    responsabilities: 'Directius d\'entitats',      
    responsabilities_responsability: 'Càrrec',   
    responsabilities_date: 'Data',   
    responsabilities_desc: 'Llistat de directius d\'entitats',    
    responsabilities_exporter_file_name: 'directius',
    responsabilities_list_entity_name: 'Entitat',
    responsabilities_list_entity_city: 'Població',
    generate: 'Generar',    
    no_results: 'No hi ha resultats'
  },
  associations : {
    subscriptions : 'Subscripcions',
    tab_data : 'Dades',
    tab_subscriptions : 'Subscripcions',
    tab_admin : 'Administradors',
    tab_statistics : 'Estadístiques',
    generate_data_demo : 'Generar dades de prova',
        
    report_users: 'Usuaris',
    report_groups: 'Grups',
    report_emails: 'Emails',
    report_emails_not_sent: 'No enviats (48h)',
    report_docs: 'Docs',
    report_mb: 'MB',
    report_remittances: 'Remeses',
    report_remittances_users: 'Rebuts',
    report_last_action: 'Última acció',
    
    trial: 'Període de prova',
    begin: 'Inici subscripció',
    end: 'Final subscripció',
    
    email_sent_tooltip_button: 'Enviar notificació',
    email_sent_tooltip_sending: 'S\'està enviant notificació...',
    email_sent_tooltip_done: 'S\'ha enviat la notificació',
    email_sent: 'Notificació',
    email_sent_title: 'Enviar notificació',
    email_sent_ask: 'Segur que vols enviar-li la notificació?' ,  
    email_sent_ask_again: 'Ja s\'ha enviat anteriorment la notificació. Segur que vols tornar a enviar-li?',        
    email_sent_ko: 'La notificació no s\'ha pogut entregar al destinatari',
        
  },
  auth: {
    error_title: 'S\'ha produït un error',
    forgot_password_title: 'No recordes la teva contrasenya?',
    forgot_password_desc: 'Introdueix el teu correu electrònic i t\'enviarem les instruccions per a crear una nova contrasenya.',
    forgot_password_ok: 'Revisa el teu correu electrònic. <br/><br/> Si ets un usuari administrador t\'hem enviat les instruccions per a canviar la contrasenya.',
    forgot_password_link: 'Ja recordo la contrasenya',
    reset_password_title: 'Escriu la teva nova contrasenya',
    reset_password_button: 'Canviar contrasenya',
    reset_password_ok: 'La teva contrasenya s\'ha actualitzat correctament',
    reset_password_ok_link: 'Anar a la pàgina d\'autenticació',
    send_button: 'Enviar',
    error: 'Error'    
  },
  signup : {
    link_or: "o",
    link: "Registra't",
    title: 'Registreu-vos per a provar l\'aplicatiu',
    register: 'Registre de nou usuari',
    desc: 'Introduiu el nom de la vostra entitat i el correu electrònic, i us enviarem l\'accés per a provar l\'aplicatiu <b>gratuïtament durant 30 dies</b>, sense introducció de dades bancàries. Transcorregut aquest període ja decidireu si voleu activar el pla de servei.',
    ok: 'Hem rebut les vostres dades correctament. <br/><br/>En breu rebreu un correu electrònic amb les credencials d\'accés a l\'aplicatiu.',
    ok_login: 'Accedir',
    name: 'Nom de l\'entitat',
    send_button: 'Registra\'t',
    
    form_lopd: 'Accepto que es tractin les meves dades per atendre la sol·licitud de registre',
    form_lopd_validator: 'Heu d\'acceptar el tractament de les dades',        
    form_lopd_link: 'política de privacitat',
    
    form_terms: 'Accepto els ',
    form_terms_link: 'termes i condicions',
    form_terms_validator: 'Heu d\'acceptar els termes i condicions',
    
    form_newsletter: 'Accepto que es tractin les meves dades per rebre les ofertes i novetats',
    
    lopd : "Política de privacitat",
    terms : "Termes i condicions",
    
  },
  
  subscriptions : {
    modules : 'Mòduls',
    module : 'Mòdul',
    options : 'Opcions',
    option : 'Opció',
    docs: 'Imatges',        
    date_init: 'Data d\'inici',
    date_expiration: 'Data d\'expiració'
  },
  permissions : {
    user : 'Usuari',
    status : 'Estat',
    status0: 'Pendent',
    status1 : 'Alta'
  },
  groups : {
    title: 'Grups',
    group: 'Grup',
    edit : 'Modificar',
    addChild: 'Afegir fill',
    tab_users : 'Persones' ,
    tab_main : 'Grup' ,
    tab_quotes : 'Quotes' ,
    tab_docs : 'Documents',
    tab_subgroups : 'Subgrups' ,
    tab_responsabilities : 'Càrrecs' , 
    num_members: 'Membres actuals' ,
    name: 'Nom del grup',
    card: 'Carnet digital',
    card_digital: 'Es poden generar carnets digitals en PDF',
    card_digital_wallets: 'Es poden generar carnets digitals a Google Wallet i Apple Wallet'
  },
  groups_cards : {
    design_title: 'Disseny del carnet',
    type: 'Element',
    pos_x: 'M.Esquerre (%)',
    pos_y: 'M.Superior (%)',
    size_x: 'Amplada (%)',
    size_y: 'Alçada (%)',
    num_lines: 'Núm. línies màxim',
    font_size: 'T.Lletra (%)',
    font_color: 'Color',
    opacity: 'Opacitat (%)',
    rectangle_round: 'Extrems arrodonits (%)',
    title: 'Nom del camp',
    message: 'Valor del camp',
    status: 'Visible',
    type_0: 'Nom de l\'entitat',
    type_1: 'Logotip de l\'entitat',
    type_2: 'Nom + Cognom1 + Cognom2 (membre)',
    type_3: 'Nom + Cognom1 (membre)',
    type_4: 'Cognom1 Cognom2, Nom (membre)',
    type_5: 'Núm. membre (membre)',
    type_6: 'Fotografia (membre)',
    type_7: 'DNI/NIF (membre)',
    type_8: 'Data d\'expedició',
    type_9: 'Data d\'alta',
    type_10: 'Codi QR',
    type_11: 'Nom del grup',
    type_12: 'Correu electrònic (membre)',
    type_13: 'Nom (membre)',
    type_14: 'Cognom1 (membre)',
    type_15: 'Cognom2 (membre)',
    type_16: 'Cognom1 + Cognom2 (membre)',
    type_17: 'Nom del centre',
    type_18: 'Població del centre',
    type_19: 'Adreça de l\'entitat',
    type_20: 'Població de l\'entitat',
    type_21: 'Telèfon de l\'entitat',
    type_22: 'Marc semi-transparent',
    type_23: 'Color de fons',
    type_24: 'Camp de text',
    alignment: 'Aliniament',
    alignment_0: 'Esquerre',
    alignment_1: 'Centrat',
    alignment_2: 'Dreta',
    alignment_3: 'Justificat',
    uppercase: 'Majúscules',
    pdf_view: 'Descarregar carnet',
    regenerate: 'Regenerar codi QR',
    regenerate_title: 'Regenerar codi QR',
    regenerate_text: 'Segur que vols regenerar el codi QR del carnet digital? Aquesta operació només s\'hauria de realitzar en cas de pèrdua o robatori del carnet. El carnet anterior deixarà de ser vàlid. Aquesta operació no envia el carnet al contacte, caldrà fer-ho manualment.',
    regenerated_ok: 'Codi QR regenerat correctament',
    pdf_card_filename: 'carnet.pdf',
    pdf_card_ok: 'El carnet s\'ha generat correctament. Consulta la carpeta de descàrregues del navegador',
    pdf_card_download: 'Previsualització',        
    send_dialog_button: 'Enviar carnets digitals',
    send_dialog_action: 'Acció a realitzar',
    send_dialog_action_0: 'Enviar el carnet digital només als contactes que no s\'ha enviat (%num_pending%)',
    send_dialog_action_1: 'Enviar el carnet digital a tots els contactes del grup (%num_total%)',     
    send_dialog_block_size: 'Límit enviament',                
    send_dialog_regenerate: 'Regenerar el codi QR. Si marques aquesta opció, els contactes que ja disposaven de carnet digital disposaran d\'un carnet nou i l\'anterior serà invàlid. Si no la marques, els usuaris que ja disposaven de carnet digital mantindran el seu codi QR, i l\'anterior carnet continuarà essent vàlid.',        
    send_dialog_text2: 'S\'enviarà als contactes que disposin de correu electrònic. L\'enviament s\'executarà en segon pla i pot trigar uns minuts. Podràs consultar l\'estat de l\'enviament a la fitxa de cada contacte o usant els filtres del llistat de contactes.',
    pdf_card_preview: 'Carregant la previsualització del carnet...',
    create: 'Afegir element',
    edit: 'Modificar element',
    background: 'Imatge de fons',
    settings_pdf: "Carnet en PDF",
    settings_wallets: "Google Wallet i Apple Wallet",
    settings_apple_wallet: "Apple Wallet",
    background_google_wallet: "Imatge inferior del carnet (només Google Wallet)",
    google_wallet_icon: "Icona",
    google_wallet_background_color: "Color de fons"
  },
  users : {
    tab_main : 'Dades bàsiques',
    tab_main_0 : 'Dades personals',
    tab_main_1 : 'Dades generals',
    tab_more : 'Més dades',
    tab_groups : 'Grups' ,
    tab_responsabilities : 'Càrrecs' ,
    tab_responsabilities_fac : 'Càrrecs a la FAC' ,
    tab_quotes : 'Quotes' ,
    tab_contacts : 'Contactes relacionats',
    tab_contacts_rel : 'Contactes relacionats',
    tab_economic_data : 'Pressupost anual',
    tab_workers_0 : 'Personal remunerat',
    tab_workers_1 : 'Voluntaris',
    tab_docs : 'Documents',
    tab_images : 'Imatges',
    tab_family : 'Família',
    tab_history : 'Historial',
    tab_social_nets : 'Xarxes socials',
    user_type: 'Tipus',
    user_type_0: 'Física',
    user_type_1: 'Jurídica',
    group: 'Grup',
    groups: 'Grups',
    without_group : 'Sense grup',
    first_name : 'Nom',
    name_company : 'Nom fiscal',
    last_name_1 : 'Primer cognom',
    last_name_2 : 'Segon cognom',
    email : 'Correu-e',
    email_secondary : 'Adreces de correu-e addicionals',
    code : 'Número intern',
    address : 'Adreça',
    neighborhood : 'Barri',
    zip : 'Codi postal',
    city : 'Població',
    region : 'Comarca',
    country : 'País',
    lat: "Coord. Latitud",
    lng: "Coord. Longitud",
    phones : 'Telèfons',
    phone : 'Telèfon fix',
    mobile : 'Telèfon mòbil',
    web: 'Pàgina web',
    phone_emergency : 'Telèfon d\'emergència',
    birthday : 'Data de naixement',
    foundation_date: 'Data de fundació',
    foundation_age : 'Antiguitat',
    job : 'Professió',
    nif : 'NIF',
    nif_only : 'NIF',
    gender : 'Gènere',
    gender_1 : 'Home',
    gender_2 : 'Dona',
    gender_3 : 'No binari',
    comment : 'Observacions',
    bank_data: 'Dades bancàries',
    bank_iban: 'IBAN',
    bank_swift: 'BIC/SWIFT',
    bank_payer: 'Pagador',
    no_users_title : 'Encara no hi ha persones.',
    no_users_ask : 'Vols afegir-ne?',
    person: 'Persona',
    payment_cash: 'Paga en efectiu',
    newsletter: 'Accepta rebre comunicacions de l\'entitat',
    age: 'Edat',
    age_sufix: 'anys',
    others: 'Altres',
    import : 'Importar',
    import_title : 'Importar persones per a aquest grup des de Excel',
    import_template : 'Descarrega la plantilla Excel',
    add_to_group: 'Afegir a',        
    add_member_comments: 'Dubtes, comentaris o suggeriments',
    add_member_send: 'Enviar',
    validate: 'Validar',
    validate_title: 'Validar soci/a',
    validate_done: 'S\'ha validat el soci/a',
    validate_send_email: 'Enviar correu electrònic predefinit de benvinguda',
    welcome_email_sent: 'Correu de benvinguda',
    welcome_email_sent_title: 'Correu de benvinguda',
    welcome_email_sent_ask: 'Segur que vols enviar-li el correu de benvinguda predefinit?' ,  
    welcome_email_sent_ask_again: 'Ja s\'ha enviat anteriorment el correu de benvinguda. Segur que vols tornar a enviar-li?',
    welcome_email_sent_ok: 'Enviament realitzat!',
    welcome_email_tooltip_button: 'Enviar correu de benvinguda',
    welcome_email_tooltip_sending: 'S\'està enviant el correu de benvinguda...',
    welcome_email_tooltip_done: 'S\'ha enviat el correu de benvinguda',
    welcome_email_sent_ko: 'El correu de benvinguda no s\'ha pogut entregar al destinatari',
        
    digital_card_send: 'Carnet digital',
    digital_card_send_title: 'Carnet digital',
    digital_card_send_ask: 'Segur que vols enviar-li el carnet digital?' ,  
    digital_card_send_ask_again: 'Ja s\'ha enviat anteriorment el carnet digital. Segur que vols tornar a enviar-li?',
    digital_card_send_ok: 'Enviament realitzat!',
    digital_card_tooltip_button: 'Enviar carnet digital',
    digital_card_tooltip_sending: 'S\'està enviant el carnet digital...',
    digital_card_tooltip_done: 'S\'ha enviat el carnet digital',
    digital_card_send_ko: 'El carnet digital no s\'ha pogut entregar al destinatari',
        
    exporter_file_name: 'contactes.xlsx',
    export: 'Exportar',
    alerts: 'Avisos',    

  },      
  users_filter: {
    title: 'Filtrar',
    filters: 'Filtres',
    field: 'Camp',
    operator: 'Operador',
    value: 'Valor',
    year: 'Any',
        
    filter_1: 'Nom',
    filter_2: 'Primer cognom',
    filter_3: 'Segon cognom',        
    filter_4: 'Correu-e principal',
    filter_5: 'Tipus de contacte',
    filter_6: 'Adreça',
    filter_7: 'Barri',
    filter_8: 'Codi postal',
    filter_9: 'Població',
    filter_10: 'Comarca',
    filter_11: 'País',
    filter_12: 'NIF',
    filter_13: 'Pàgina web',
    filter_14: 'Data naixement',
    filter_15: 'Data de fundació',
    filter_16: 'Edat',
    filter_17: 'Antiguitat',
    filter_18: 'Correu-e addicional',
    filter_19: 'IBAN',
    filter_20: 'Quota',
    filter_21: 'Últim rebut',
    filter_22: 'Nom complet',
    filter_23: 'Telèfon fix',
    filter_24: 'Telèfon mòbil',
    filter_25: 'Telèfon emergència',
    filter_26: 'Gènere',
    filter_27: 'Professió',
    filter_28: 'Accepta comunicacions',
    filter_29: 'BIC/SWIFT',
    filter_30: 'Paga en efectiu',
    filter_31: 'Fotografia',
    filter_32: 'Documents',
    filter_33: 'Grup: Data alta al grup',
    filter_34: 'Grup: Núm. membre',
    filter_35: 'Xarxes socials',
    filter_36: 'Observacions',
    filter_37: 'Avisos',
    filter_38: 'Grup',  
    filter_39: 'Centre',  
    filter_40: 'Imatges',  
        
    filter_50: 'Grup: S\'ha enviat el correu de benvinguda',
    filter_51: 'Grup: S\'ha enviat el carnet digital',
        
    /* FEDERACIO */
    filter_80: 'Adreces: Adreça',
    filter_81: 'Adreces: Codi postal',
    filter_82: 'Adreces: Població',
    filter_83: 'Adreces: Comarca',
    filter_84: 'Adreces: Província',
    filter_85: 'Adreces: País',
    filter_86: 'Adreces: Adreça d\'enviament',
    filter_87: 'Adreces: Codi postal d\'enviament',
    filter_88: 'Adreces: Població d\'enviament',
    filter_89: 'Adreces: Comarca d\'enviament',
    filter_90: 'Adreces: Província d\'enviament',
    filter_91: 'Adreces: País d\'enviament',
        
    filter_99: 'Nom popular',
    filter_100: 'Acrònim',
    filter_101: 'Nom del registre',
    filter_102: 'Núm. del registre',
    filter_103: 'BCIN',
    filter_104: 'BCIL',
    filter_105: 'Sistema de venda d\'entrades',
    filter_106: 'Forma part de l\'XTAC',
    filter_107: 'Entitat interès cultural',
    filter_108: 'Entitat d\'utilitat pública',
    filter_109: 'Accés al Programa Cat',
    filter_110: 'Últim canvi de junta',
    filter_111: 'Proper canvi de junta',
    filter_112: 'Delegació',
    filter_113: 'Acull altres entitats',
    filter_114: 'Entitats que acull',    
    filter_121: 'Carnets de socis',
    filter_122: 'Logo de la FAC al carnet',
    filter_123: 'Reglament intern',    
    filter_125: 'Placa de la FAC',
    filter_126: 'Llicència d\'activitats',        
    filter_127: 'Titularitat de l\'ateneu',
    filter_128: 'La propietat és',
    filter_129: 'Cedit/Llogat',
    filter_130: 'Expropiat actualment',
    filter_131: 'Adaptats a la nova LOPD',
    filter_132: 'Normatives vigents de',
    filter_133: 'Memòria',
    filter_134: 'Forma part d\'altres federacions',
    filter_135: 'Federacions que forma part',
    filter_136: 'Carnet RCAT',
    filter_137: 'Entitat activa',
    filter_138: 'Entitat desapareguda',
    filter_139: 'Espoliada pel franquisme',
    filter_140: 'Data desaparició',
    filter_141: 'Personatge vinculat a ressaltar',    
        
    filter_200: 'Espais',
    filter_201: 'Espais: Total m2',
    filter_202: 'Espais: m2',
    filter_203: 'Espais: capacitat',
        
    filter_300: 'Pressupost anual',
    filter_301: 'Pressupost: total ingressos',
    filter_302: 'Pressupost: total despeses',
    filter_303: 'Pressupost: total inversió obres',
    filter_304: 'Pressupost: resultat',
    filter_305: 'Pressupost (ingressos): quotes de socis',
    filter_306: 'Pressupost (ingressos): serveis oferts',
    filter_307: 'Pressupost (ingressos): subvencions',
    filter_308: 'Pressupost (ingressos): arrendaments',
    filter_309: 'Pressupost (ingressos): venda de productes',
    filter_310: 'Pressupost (ingressos): ajuts privats',
    filter_311: 'Pressupost (ingressos): altres',
    filter_312: 'Pressupost (despeses): lloguers',
    filter_313: 'Pressupost (despeses): personal',
    filter_314: 'Pressupost (despeses): material',
    filter_315: 'Pressupost (despeses): professionals externs',
    filter_316: 'Pressupost (despeses): catxés de grups',
    filter_317: 'Pressupost (despeses): subministraments',
    filter_318: 'Pressupost (despeses): manteniment',
    filter_319: 'Pressupost (despeses): comunicació i publicitat',
    filter_320: 'Pressupost (despeses): altres',
    filter_321: 'Pressupost (inversió obres): edifici',
    filter_322: 'Pressupost (inversió obres): teatre',
    filter_323: 'Pressupost (inversió obres): bar',
    filter_324: 'Pressupost (inversió obres): accessibilitat',
    filter_325: 'Pressupost (inversió obres): seguretat',
    filter_326: 'Pressupost (inversió obres): altres',
    filter_327: 'Pressupost: Mitjana de la quota anual',
    
    filter_350: 'Dades demogràfiques',
    filter_351: 'Dades demogràfiques: Núm. de socis',
    filter_352: 'Dades demogràfiques: Mitjana d\'edat',
    filter_353: 'Dades demogràfiques: Mitjana d\'edat junta directiva',
    filter_354: 'Dades demogràfiques: Homes (%)',
    filter_355: 'Dades demogràfiques: Dones (%)',
    filter_356: 'Dades demogràfiques: No binaris (%)',
       
    filter_400: 'Personal remunerat',
    filter_401: 'Personal remunerat: total personal',
    filter_402: 'Personal remunerat: administració',
    filter_403: 'Personal remunerat: tècnic d\'activitats',
    filter_404: 'Personal remunerat: consergeria',
    filter_405: 'Personal remunerat: neteja',
    filter_406: 'Personal remunerat: comunicació',
    filter_407: 'Personal remunerat: comptabilitat',
    filter_408: 'Personal remunerat: manteniment',
    filter_409: 'Personal remunerat: gerència',
    filter_410: 'Personal remunerat: altres',
        
    filter_450: 'Voluntaris',
    filter_451: 'Voluntaris: total',
    filter_452: 'Voluntaris: administració',
    filter_453: 'Voluntaris: tècnic d\'activitats',
    filter_454: 'Voluntaris: consergeria',
    filter_455: 'Voluntaris: neteja',
    filter_456: 'Voluntaris: comunicació',
    filter_457: 'Voluntaris: comptabilitat',
    filter_458: 'Voluntaris: manteniment',
    filter_459: 'Voluntaris: gerència',
    filter_460: 'Voluntaris: altres',
    
    filter_470: 'Formacions en arts: total hores',
    filter_471: 'Formacions en arts: estudiants per formació',
    filter_472: 'Formacions en arts: estudiants per tipus de formació',
    filter_473: 'Formacions en arts: professorat remunerat per formació',
    filter_474: 'Formacions en arts: professorat remunerat per tipus de formació',
    filter_475: 'Formacions en arts: professorat voluntari per formació',
    filter_476: 'Formacions en arts: professorat voluntari per tipus de formació',
    
    filter_480: 'Formacions: total hores',
    filter_481: 'Formacions: estudiants per formació',
    filter_482: 'Formacions: estudiants per tipus de formació',
    filter_483: 'Formacions: professorat remunerat per formació',
    filter_484: 'Formacions: professorat remunerat per tipus de formació',
    filter_485: 'Formacions: professorat voluntari per formació',
    filter_486: 'Formacions: professorat voluntari per tipus de formació',
    
    filter_490: 'Projectes socials',
    filter_491: 'Projectes socials per tipus',
       
    filter_500: 'Activitats',
    filter_501: 'Activitats: total activitats anuals',        
    filter_502: 'Activitats: total assistents anuals',
    filter_503: 'Activitats: quantitat',
    filter_504: 'Activitats: assistents',
        
    filter_600: 'Espectacles',
    filter_601: 'Espectacles: total funcions',
    filter_602: 'Espectacles: total assistents',
    filter_603: 'Espectacles: total pressupost',
    filter_604: 'Espectacles: funcions',
    filter_605: 'Espectacles: assistents',
    filter_606: 'Espectacles: pressupost',
        
    filter_700: 'Seccions',
    filter_701: 'Seccions: núm. de seccions',
    filter_702: 'Seccions: total participants',
    filter_703: 'Seccions: participants',
                
    filter_750: 'Ha respost el formulari per a l\'anuari',
    filter_751: 'S\'ha enviat el formulari per a l\'anuari',
    filter_752: 'S\'ha enviat el recordatori del formulari',
       
    filter_800: 'Forma part d\'un cens',
    filter_801: 'Llibres',
    filter_802: 'Enviaments',
                                
    filter_op_1: 'Conté',        
    filter_op_2: 'És igual a',
    filter_op_3: 'És diferent a',   
    filter_op_4: 'És major que',
    filter_op_5: 'És major o igual que',
    filter_op_6: 'És menor que',
    filter_op_7: 'És menor o igual que',   
    filter_op_8: 'No conté',
    filter_op_9: 'En té',
    filter_op_10: 'No en té',
        
  },
    
  users_permissions: {
    email_sent: 'Correu d\'alta d\'administrador/a',
    email_sent_title: 'Correu d\'alta d\'administrador/a',
    email_sent_ask: 'Segur que vols enviar-li el correu d\'alta d\'administrador/a predefinit? Es generarà una nova contrasenya.' ,  
    email_sent_ask_again: 'Ja s\'ha enviat anteriorment el correu d\'alta d\'administrador/a. Segur que vols tornar a enviar-li? Es generarà una nova contrasenya.',
    email_sent_ok: 'Enviament realitzat!',
    email_tooltip_button: 'Enviar correu d\'alta d\'administrador/a',
    email_tooltip_sending: 'S\'està enviant el correu de d\'alta d\'administrador/a...',
    email_tooltip_done: 'S\'ha enviat el correu d\'alta d\'administrador/a',
    email_sent_ko: 'El correu d\'alta d\'administrador no s\'ha pogut entregar al destinatari',  
    role: 'Permisos',
    role_SIFAC: 'Superadministrador',
    role_ADMIN: 'Administrador',
    role_ADMIN_CONTACTS: 'Administrador (lectura + edició contactes)',
    role_ADMIN_READER: 'Administrador (lectura)',
  },
        
  users_federation : {
    tab: 'Dades Federació',
    tab_more: 'Més Federació',
    tab_managers: 'Junta directiva',
    tab_managers_1: 'Junta directiva actual',
    tab_managers_2: 'Membres d\'anteriors juntes directives',
    tab_managers_other: 'Membre de juntes directives d\'altres contactes',
    tab_classrooms: 'Espais',  
    name: 'Nom popular',
    acronym: 'Acrònim',
    reg_name: 'Nom del registre',
    reg_num: 'Núm. del registre',    
    bcin: 'BCIN',
    bcil: 'BCIL',
    tickets: 'Sistema de venda d\'entrades',
    xtac: 'Forma part de l\'XTAC',
    culture_interest: 'Entitat interès cultural',
    public_utility: 'Entitat d\'utilitat pública',
    soft_cat: 'Accés al Programa Cat',
    rcat: 'Carnet RCAT',
    last_managers_change: 'Últim canvi de junta',
    next_managers_change: 'Proper canvi de junta',
    delegation: 'Forma part d\'una delegació?',
    host: 'S\'acullen altres entitats',
    host_entities: 'Quines entitats?',
    more_federations: 'Forma part d\'altres federacions',
    federations: 'Quines federacions?',
    lopd: 'Adaptats nou Reglament Europeu Protecció Dades (LOPD)',
    members_cards: 'Carnets de socis',
    logo_federation: 'Hi ha logo de la FAC',
    internal_regulation: 'Reglament intern',
    federation_plate: 'Placa de la FAC',
    ownership: 'Titularitat de l\'ateneu',
    property: 'La propietat és',
    rent: 'Cedit/Llogat',
    expropied: 'Està expropiat actualment',
    license: 'Té llicència d\'activitats',
    regulations: 'Normatives vigents de',
    
    actived: 'Entitat activa',
    missing: 'Entitat desapareguda',
    robbed: 'Espoliada pel franquisme',
    missing_date: 'Data desaparició',
    history_title: 'Història',
    history: 'Història Ateneu - L\'entitat',
    history_equipment: 'Història entitat - L\'equipament, infraestructura',
    history_current: 'Història Ateneu - Situació actual',
            
    census: 'Forma part d\'un cens',
    mailing_lists: 'Enviaments',
    activities_users: 'Etiquetes',
    books: 'Llibres',
                            
    edited_books_title: 'Publicacions de l\'entitat',
    edited_books_books: 'Llibres editats',
    edited_books_book_entity: 'Llibre de l\'ateneu',
    edited_books_newsletter: 'Butlletí electrònic',
    edited_books_magazine: 'Revista',
    highlighted_dates: 'Dates a ressaltar',
    notable_individuals: 'Personatges vinculats a ressaltar',
    links: 'Enllaços d\'interès',
    edited_books_memory: 'Memòria',
    edited_books_date: 'Data',
    edited_books_period: 'Periodicitat',
    event: 'Esdeveniment',
        
    recognitions: 'Reconeixements',
    recognitions_date: 'Data',
        
    schedules: 'Horaris',
    schedules_day_week: 'Dia',
    schedules_time_start: 'Hora inici',
    schedules_time_finish: 'Hora fi',
                
    addresses: 'Adreces',
    ship_address: 'Adreça d\'enviament',
    province: 'Província',
    gmaps: 'Google Maps',
    addresses_create: 'Afegir adreça',
    addresses_edit: 'Modificar adreça',
    is_foundation_address: 'És l\'adreça de fundació de l\'entitat',
    ship_to_same: 'L\'adreça d\'enviament és la mateixa adreça',
    building: 'Edifici',
    regultations: 'Regulacions',
    others: 'Altres',
    classrooms: 'Espais',        
    total_m2: 'Total m2',    
        
  },
    
  users_groups :{
    date_join : 'Data alta',
    date_left : 'Data baixa',
    responsability: 'Càrrec',
    num_member : 'Núm. membre',
    last_num_inserted: 'Núm. membre més recent: ',
    exporter_file_name: 'carrecs',
    qr_code: 'Codi QR'
  },
  groups_quotes: {
    title: 'Periodicitats i imports',
    date_payment : 'Data de pagament',
    month_payment : 'Inici',
    period : 'Periodicitat',
    quantity: 'Import',
    apply_to_all: 'Aplicar la quota a tots els membres automàticament',
    apply_to_all_list: 'S\'aplica automàticament',
  },
  users_groups_quotes : {
    group: 'Grup',
    group_quote : 'Quota',
    group_quote_period : 'Periodicitat',
    quantity : 'Import',
    discount_ini : 'Inici',
    discount_end : 'Fi',
    discount_percent : 'Dte (%)',        
    discount_quantity : 'Dte (€)',
    manual_quantity : 'Import manual (€)',
    comment: 'Comentari'
  },
  remittances: {
    tab_main: 'Remesa',
    tab_remittances_users: 'Rebuts',
    concept: 'Concepte',
    quantity: 'Import',
    date_creation : 'Data',
    count_users: 'Rebuts',
    sum_quantity : 'Import',
    sepa: 'Fitxer SEPA',
    pdf: 'Rebuts PDF',
    link_user: 'Persona',
    status: 'Estat',
    status0: 'Acceptat',
    status1: 'Impagat',
    statusFilterAll: 'Tots',
    statusFilter0: 'Acceptats',
    statusFilter1: 'Impagats',        
    payer: 'Pagador',
    iban: 'IBAN',
    nif: 'NIF',       
    status_form: 'Canviar estat del rebut', 
    status_new: 'Nou estat', 
    check_reemit: 'Es podrà tornar a generar el rebut en una nova remesa',
    check_sent_return_notification: 'Enviar la notificació d\'impagament al prémer Desar',
    comments: 'Observacions',
    comments_form: 'Observacions sobre l\'estat del rebut',
    default_name: 'Remesa_',
    groups_with_quotes: 'Generar automàticament els rebuts de',
    edit_state : 'Modificar',
    returns: 'Incorporar rebuts impagats d\'altres remeses',
    refresh_payer: 'Actualitzar dades',
    refresh_payer_confirm: 'S\'han actualitzat les dades de pagament de l\'usuari',
    no_remittances_users_title : 'remeses de rebuts',
    no_remittances_users_ask : 'Vols afegir-ne una?',
    payer_payment_cash : 'És un pagament en efectiu',
    payment_system : 'Forma de pagament',
    bank: 'Rebut bancari',
    cash : 'Efectiu',
    pdf_filename: 'rebuts',
    pdf_ok: 'El fitxer s\'ha generat correctament. Consulta la carpeta de descàrregues del navegador',
    ref: 'Ref.',
    resume_total_plural: 'rebuts a la remesa',
    resume_total: 'rebuts a la remesa',
    resume_bank_plural: 'rebuts domiciliats',
    resume_bank: 'rebut domiciliat',
    resume_cash_plural: 'rebuts en efectiu',
    resume_cash: 'rebut en efectiu',
    create: 'Afegir rebut', 
    create_remittance: 'Afegir remesa' ,
    check_partial_quotes: 'Incloure quotes proporcionals dels nous membres. Per exemple, si hi ha un nou membre al febrer i la propera quota periòdica no és fins el gener de l\'any següent, es generarà un rebut amb la part proporcional dels mesos restants.',
    name: 'Remesa',
    last_receips: 'Últims rebuts emesos',
    send_returned_notification_title: 'Enviar notificació d\'impagament',
    send_returned_notification_content: 'Segur que vols enviar-li el correu d\'impagament predefinit?',
    send_returned_notification_content_again: 'Ja s\'ha enviat anteriorment el correu. Segur que vols tornar a enviar-li el correu d\'impagament predefinit?',  
    send_returned_ko: 'El correu no s\'ha pogut entregar al destinatari',
    send_returned_tooltip_button: 'Enviar notificació d\'impagament',
    send_returned_tooltip_sending: 'S\'està enviant la notificació d\'impagament...',
    send_returned_tooltip_done: 'S\'ha enviat la notififcació d\'impagament',        
    exporter_file_name: 'rebuts',
    exporter_header_returned_status: 'Notificació d\'impagament enviada'
  },   
  ibans : {
    create_iban: 'Afegir compte',
    public_id: 'Identificador',
    name: 'Nom del compte',
    ordering: 'Ordenant',
    iban: 'IBAN',
    swift: 'BIC/SWIFT',
    sufix: 'Sufix',
    nif: 'NIF/CIF',
    returns_tax: 'Comissió de devolució (€)',
    bank_country_iso: 'País (ISO)',        
    create: 'Afegir compte', 
    edit: 'Editar compte', 
    no_ibans: 'comptes configurats',
    name_create_remittance: 'Compte',
  },
  payment_systems: {
    main_menu_title: 'Passarel·les bancàries',
    create: 'Afegir passarel·la',
    edit: 'Modificar passarel·la',
    public_id: 'Identificador',
    plattform: 'Tipus',
    plattform_0: 'Redsys',
    plattform_1: 'Addon Payments',
    name: 'Nom',
    merchant: 'Comerç',
    pswd: 'Contrasenya',
    terminal: 'Terminal / Account',
    url_ok: 'Url retorn OK',
    url_ko: 'Url retorn KO',
    url_async: 'Url de notificació',
    real: 'Entorn real',
    url: 'Url connexió (mode REAL)',
    url_test: 'Url connexió (mode TEST)',
    currency: 'Moneda',
    encrypt: 'Clau d\'encriptació',
    transaction_type: 'Tipus de transacció',
    exporter_file_name: 'pasarelles',
  }, 
  donations_projects: {
    id: 'Id projecte',
    main_menu_title: 'Donatius',
    associations_id: 'Id entitat',
    associations_name: 'Entitat',
    reports_title: 'Projectes per a rebre donacions de les entitats federades',    
    name: 'Projecte',
    create: 'Afegir projecte',
    description_short: 'Descripció curta per mostrar al llistat públic de projectes',
    description: 'Descripció llarga',
    video: 'Adreça de vídeo Youtube',
    date_start: 'Inici de la campanya',
    date_finish: 'Finalització',
    minimum_amount: 'Import mínim del donatiu (€)',
    amount: 'Objectiu recaptació (€)',
    public_entity: 'Públic a la web',
    public_fac: 'Públic a la web de la FAC',
    public_sum_donations: 'Publicar total aportacions',
    payment_card: 'Targeta',
    payment_systems_id: 'Passarel·la de pagament',
    payment_systems_fac: 'Formes de pagament de la FAC',
    payment_transfer: 'Transferència bancària',
    payment_transfer_ibans_id: 'Al compte bancari',
    ibans_iban: 'Abonament al compte bancari',
    legal_text_1: 'Text legal',
    legal_text_2: 'Text legal',
    legal_text_3: 'Paràgraf de justificació en el certificat de donació',
    confirmation_text_1: 'Text de confirmació',
    confirmation_text_2: 'Text de confirmació',
    confirmation_email_1: 'Text correu-e de confirmació',
    count_donations: 'Finançadors',
    sum_donations: 'Import finançadors',
    sum_donations_percent: '% recaptat',
    remaining_days: 'Temps restant',
    minute: 'minut',
    minutes: 'minuts',
    hour: 'hora',
    hours: 'hores',
    day: 'dia',
    days: 'dies',
    seconds: 'segons',
    second: 'segon',
    filter_only_current: 'Projectes vigents',
    only_pending: 'Pendents de validació',
    images: 'Imatges',
    legal_text: 'Textos legals',
    payment_systems: 'Forma de pagament',
    public: 'Publicació',
    preview: 'Previsualització',
    view: 'Web',
    fac_validation_status: 'Validació FAC',
    fac_validation_status_0: 'Mode edició',
    fac_validation_status_1: 'Pendent de validació',
    fac_validation_status_2: 'Validat',
    fac_validation_status_3: 'Rebutjat',
    fac_validation_actions: 'Accions',
    fac_validation_actions_1: 'Sol·licitar validació',
    edit: 'Modificar',
    validate: 'Validar',
    target_by_date: 'Objectiu en un interval de dates',
    reports: 'Donatius: Projectes de les entitats',
    reports_desc: 'Validar els projectes de les entitats federades, consultar mecenes i afegir donatius en efectiu o transferència bancària.',
    reports_transfer_validations: 'Donatius: Validació de transferències bancàries',    
    reports_transfer_validations_desc: 'Validar els donatius realitzats mitjançant transferència bancària.',
    reports_payments: 'Donatius: Pagaments a les entitats',
    reports_payments_desc: 'Consulta dels imports a transferir a les entitats',
    reports_certificates: 'Donatius: Certificats de donació',
    reports_certificates_desc: 'Genera els certificats de donació',    
    validate_request: 'Sol·licitar validació',
    validate_request_button: 'Sol·licitar',
    validate_request_desc: 'Sol·licitar que la FAC revisi i validi el projecte per a publicar-se i poder començar a rebre donatius. A partir d\'aquest moment no podreu modificar el projecte',
    exporter_file_name: 'projectes',
    view_web: 'Veure a la web',
    friendly_url: 'URL pública',
    city: 'Població',
    phone: 'Telèfon de contacte',
    email: 'Correu-e de contacte',
    friendly_url: 'Adreça pública',
    comments_actived: 'Comentaris actius',
    contact_data: 'Dades de contacte',
  }, 
  public_donations_projects: {
    not_actived: 'L\'entitat no té projectes de donatius actius',
    for: 'Per',
    live: 'En marxa!',
    early: 'Començarà aviat',
    finished: 'Finalitzat!',
    count_donations: 'Finançadors',
    goal: 'Objectiu',
    view_all_projects: 'Tots els projectes',
    remain: 'Queden',
    remain_1: 'Queda',
    win: 'Aconseguits',
    more_info: '+ Info',
    contribute: 'Aportar',
    association_not_found : 'L\'entitat no s\'ha trobat o hi ha hagut un error',
    not_found : 'El projecte no s\'ha trobat o hi ha hagut un error',
    view_web: 'Agenda web',
    go_back: 'Tornar enrere',
    search: 'Cercar',
    filter_date_after: 'Comença després de',
    filter_date_before: 'Comença abans de',
    filter_organizer_all: 'Totes les entitats',
    comments: 'Comentaris de finançadors',
    ago: 'fa',
    close: 'D\'acord',
    return: 'Tornar',
    preview: 'Aquesta pàgina és una previsualització del projecte'
  },
  donations_users: {
    main_menu_title: 'Finançadors',
    create: 'Afegir donatiu',
    create_to: 'Fer aportarció a ',
    edit: 'Modificar',
    cancel: 'Cancel·lar',
    pay: 'Pagar',
    donations_projects_id: 'Id projecte',
    donations_projects_name: 'Nom projecte',
    associations_id: 'Id entitat',
    associations_name: 'Entitat',
    order_id: 'Referència',
    date_time: 'Data donació',
    user_type: 'Persona',
    first_name: 'Nom',
    last_name_1: 'Cognom 1',
    last_name_2: 'Cognom 2',
    email: 'Correu-e',
    nif: 'DNI/NIF',
    address: 'Adreça',
    zip: 'Codi postal',
    city: 'Població',
    country: 'País',
    phone: 'Telèfon',
    payment_method: 'Forma de pagament',
    payment_method_0: 'Transferència',
    payment_method_1: 'Targeta',
    payment_method_2: 'Efectiu',
    amount: 'Import',
    status: 'Estat',
    status_0: 'Pendent',
    status_1: 'Confirmat',
    status_2: 'Rebutjat',
    date: 'Data de l\'ingrés',    
    send_confirmation: 'Enviar correu de confirmació al donant',
    transaction_id: 'Codi de transacció',
    comment: 'Comentari',
    certificate: 'Certificat donació',
    send_status: 'Certificat enviat',
    send_date_time: 'Data enviament',
    confirmation_status: 'Confirmació enviada al donant',
    confirmation_date_time: 'Data enviament',
    only_accepted: 'Només pagaments confirmats',
    only_pending: 'Només pagaments pendents',    
    only_certificate_not_sent: 'Només certificats no enviats',
    pdf_download: 'Descarregar',
    validate: 'Confirmar',
    validate_transfer: 'Confirmar transferència',
    pdf_certificate_filename: 'certificat_donacio.pdf',
    pdf_certificate_ok: 'El certificat de donació s\'ha generat amb èxit. Consulteu la carpeta de descàrregues del navegador.',
    actions: 'Accions',
    email_sent: 'Certificat de donació',
    email_sent_title: 'Certificat de donació',
    email_sent_ask: 'Segur que vols enviar-li el certificat de donació?' ,  
    email_sent_ask_all: 'Segur que vols enviar el certificat de donació predefinit a totes les persones que encara no s\'hagi enviat?' ,  
    email_sent_ask_again: 'Ja s\'ha enviat anteriorment el certificat de donació. Segur que vols tornar a enviar-li?',        
    email_send_all_button: 'Certificats de donació',
    email_send_all_button_title: 'Enviar certificats de donació',
    email_sent_ko: 'El certificat de donació no s\'ha pogut entregar al destinatari',
    email_sent_tooltip_button: 'Enviar certificat de donació',
    email_sent_tooltip_sending: 'S\'està enviant el certificat de donació...',
    email_sent_tooltip_done: 'S\'ha enviat el certificat de donació',
    email_sent_confirmation: 'Confirmació del donatiu',
    email_sent_confirmation_title: 'Confirmació del donatiu',
    email_sent_confirmation_ask: 'Segur que vols enviar-li la confirmació de la donació?' ,  
    email_sent_confirmation_ask_again: 'Ja s\'ha enviat anteriorment la confirmació de la donació. Segur que vols tornar a enviar-li?',        
    email_sent_confirmation_ko: 'La confirmació del donatiu no s\'ha pogut entregar al destinatari',
    email_sent_confirmation_tooltip_button: 'Enviar confirmació del donatiu',
    email_sent_confirmation_tooltip_sending: 'S\'està enviant confirmació del donatiu...',
    email_sent_confirmation_tooltip_done: 'S\'ha enviat la confirmació del donatiu',
    exporter_file_name: 'financadors',
    want_certificate: 'Vol certificat de donació',
    copy: 'Copiar',
    copy_title: 'Copiar transferència',
    donations_users_rel: 'Donatiu relacionat'
  }, 
  donations_certificates : {
      title: 'Certificats de donació',
      generate: 'Veure',
  },
  donations_payments : {
      title: 'Pagaments a les entitats',
      date_from: 'Des de',
      date_to: 'Fins',
      generate: 'Veure',      
      reports_title: 'Pagament de les donacions a les entitats entre el %date_from% i %date_to%',
      exporter_file_name: 'pagaments'
  },
  postal_labels : {
    create: 'Afegir format',
    edit: 'Editar format',
    name: 'Nom',
    colxrows: "Columnes x files",
    margins_cell: "Marges de l'etiqueta (cm)",
    margins_page: "Marges de la pàgina (cm)",
    num_columns: "Núm. columnes",
    num_rows: "Núm. files",   
    template: "Plantilla",
    available_fields: "Camps disponibles:",
    fields: "%nom% | %cognom1% | %cognom2% | %adreca% | %cp% | %poblacio% | %comarca% | %provincia% | %pais%",
    font_size: "Tamany de lletra (punts)",
    line_separator: "Separació entre línies (punts)",
    margin_cell_left: "Esquerre",
    margin_cell_top: "Superior",
    margin_cell_right: "Dret",
    margin_cell_bottom: "Inferior",
    margin_page_left: "Esquerre",
    margin_page_top: "Superior",
    margin_page_right: "Dret",
    margin_page_bottom: "Inferior",
    last_row: "Emplenar l'última fila de cada pàgina",
    priority: "Ordre",    
    generate_button_title : "Etiquetes",
    generate_modal_title : "Generar etiquetes postals",
    generate_modal_desc : "Vols generar un document PDF (DIN A4) amb les etiquetes de les adreces postals dels contactes?",
    generate_modal_button : "Generar",
    generate_format : "Format de la pàgina (columnes x files)",    
    generate_pdf_filename: "etiquetes.pdf",
    generate_ok_message : "Les etiquetes s'han generat amb èxit. Consulteu la carpeta de descàrregues del navegador.",
    add_formats: "Afegir formats",
  },
  classrooms: {
    no_classrooms_title : 'Encara no hi ha espais',
    no_classrooms_ask : 'Vols afegir-ne un?',
    create: 'Afegir espai',
    seats: 'Capacitat (persones)',
    type: 'Tipus d\'espai',
    type0: 'Cafeteria / Restaurant',
    type1: 'Teatre',
    type2: 'Sala exposicions',
    type3: 'Arxius',
    type4: 'Biblioteca',
    type5: 'Equipaments esportius',
    type6: 'Sales polivalents',
    type7: 'Sales de cinema',
    type8: 'Auditori',
    type9: 'Jardí/Pati exterior',
    type10: 'Altres',
    m2: 'Tamany (m2)',
    is_public: 'L\'espai és visible a la pàgina web',
    is_requestable: 'Es pot demanar l\'ús d\'aquest espai a través de la pàgina web',
    is_for_events: 'Es poden programar esdeveniments en aquest espai',
    data: 'Dades',
    photos: 'Fotografies',
    docs: 'Fitxa tècnica',
    color: 'Color en el calendari',
    overlapable: 'Es permet la superposició d\'esdeveniments en aquest espai',
    link_back: 'Tornar',
    accessible: 'És accessible',
    cession: 'En cessió',
    rent: 'Llogat',
    rentable: 'Disponible per a llogar',
    lift: 'Té ascensor',
    visibility: 'Visibilitat',
    events: 'Esdeveniments',
    outdoor: 'És exterior',
  },
  real_state: {
      create: 'Afegir immoble',      
      address: "Adreça",
      city: "Població",
      use: "Ús",
      use_0: "Altres",
      use_1: "En lloguer",
      use_2: "En cessió",
      use_others: 'Altres usos',
      identifier: "Identificador",
      acquisition_date: 'Data d\'adquisició',
      sale_date: 'Data de venda',
      amount: 'Valor comptable',
      guaranteed_value: 'Valor assegurat',
      cadastral_reference: 'Referència cadastral',
      comments: 'Observacions',
      docs: 'Documents',
      description: 'Descripció',
      insurance: 'Assegurança',
      insurances: 'Assegurances',
      docs_deed: 'Escriptures propietat, registre mercantil...',
      docs_map: 'Plànols',
      activities: 'Activitat',
      activities_in_real_state: 'Activitats en immobles',
      payments: 'Despeses',
      agreements: "Convenis",
      filter_only_current: "Només actuals",      
      exporter_file_name: 'immobles',
  },
  real_state_activities : {      
      create: 'Afegir activitat',
      name: 'Nom de l\'activitat',
      user: 'Contacte',
      type: 'Tipus de relació',
      type_0: 'Altres',
      type_1: 'Conveni',
      type_2: 'Contracte',
      type_other: 'Tipus de relació',
      agreements_id: 'Conveni',
      date_start: "Data inici",
      date_finish: "Data final",
      date_alert: "Data preavís",
      insurance_who: 'Responsable assegurança',
      insurance_who_0: 'La persona',
      insurance_who_1: 'Nosaltres',
      relations: 'Relació amb la comunitat propietaris',      
      comments: 'Observacions',
      docs: 'Documents',
      docs_contracts: 'Contractes',
      docs_insurances: 'Assegurances',
      real_state_id: 'Id immoble',
      real_state: 'Nom immoble',
      exporter_file_name: 'activitat_immobles'
  },
  real_state_payments : {
      create: 'Afegir despesa',
      type: 'Tipus',
      type_0: 'Altres',
      type_1: 'Llum',
      type_2: 'Aigua',
      type_3: 'Gas',
      type_4: 'Telèfon',
      type_5: 'Assegurança',
      type_6: 'Obres',
      type_7: 'Manteniment',
      type_8: 'Reparacions',
      type_9: 'Transformacions',
      type_other: 'Tipus',
      date_payment: "Data pagament",
      amount: 'Import',
      payer: 'Pagador',
      payer_0: 'Altres',
      payer_1: 'Nosaltres',
      payer_2: 'Comunitat',
      payer_3: 'Llogater',
      payer_other: 'Pagador',
      financing: 'Finançament',
      financing_type: 'Tipus de finançament',
      docs: 'Documents',
      docs_billing: 'Factures',
      comments: 'Observacions',
      exporter_file_name: 'pagaments'
  },
  agreements: {
      create: 'Afegir conveni',
      users: "Persones",
      date_start: "Data inici",
      date_finish: "Data final",
      date_alert: "Data preavís",
      date_renewal: "Data renovació",
      renewal_duration: "Duració renovació",
      date_renewal_quote: "Renovació quota",
      comments: 'Observacions',
      users: "Contactes",
      real_state: "Immobles",      
      docs: 'Documents',
      filter_only_current: "Només vigents",
      exporter_file_name: 'convenis',
  },
  classrooms_activities: {
      create: 'Afegir activitat',
  },
  classrooms_payments: {
      create: 'Afegir pagament',
  },
  insurances: {
      create: 'Afegir assegurança',
      real_state: 'Immobles',
      date_start: 'Data inici',
      date_finish: 'Data final',
      date_alert: 'Data alerta',
      date_renewal: 'Data renovació',
      renewal_duration: 'Durada renovació',
      comments: 'Observacions',
      docs: 'Documents',
      filter_only_current: "Només vigents",
      exporter_file_name: 'assegurances',
  },
  alerts: {      
      create: 'Afegir alerta',      
      type: 'Tipus',
      type_1: 'Genèrica puntual',
      type_2: 'Periòdica anual',
      type_3: 'Periòdica mensual',
      type_4: 'Preavís activitat immoble',
      type_5: 'Preavís conveni',
      type_6: 'Renovació conveni',
      type_7: 'Actualització quota conveni',
      type_8: 'Preavís assegurança',
      type_9: 'Esdeveniment',
      message: 'Missatge a mostrar',
      date_alert: 'Data puntual',
      date_day: 'Dia del mes',
      date_month: 'Mes',
      actions: 'Accions',
      action_send_email: 'Enviament de correu electrònic als administradors',
      action_popup: 'Mostrar missatge a la finestra d\'alertes dels administradors',
      actived: 'Activa',
      delete_all: 'Esborrar totes les alertes',
      no_results: 'No hi ha alertes',
      filter_only_current: 'Només actives',      
      postpone: 'Posposar alerta',
      date_apply: 'Nova data',
      postpone_ok: 'S\'ha posposat l\'alerta',
      delete: 'Esborrar',
      delete_ok: 'S\'ha esborrat l\'alerta',
      delete_all_ok: 'S\'han esborrat totes les alertes',
  },  
  docs: {
    docs: 'Documents',
    create: 'Afegir',
    edit: 'Canviar el nom',
    actions: 'Accions',
    download: 'Descarregar',
    size : 'Tamany',
    fieldExcel : 'Document .xlsx (màx. %size%MB)',
    fieldDoc : 'Document (màx. %size%MB)',
    fieldImg : 'Imatge (màx. %size%MB)',
    change : 'Canviar',
    webcam: 'Foto des de webcam',
    webcam_capture: 'Capturar',
    webcam_error: 'No s\'ha detectat la webcam',
    delete_photo_title : 'Esborrar fotografia',
    delete_photo_ask : 'Segur que vols esborrar la fotografia?',        
    imported: 'Importació finalitzada',
  },
  events: {
    create: 'Afegir esdeveniment',
    edit: 'Modificar',
    name: 'Nom de l\'esdeveniment',
    description: 'Descripció',
    start: 'Data inici',
    end: 'Data fi',
    classroom: 'Espai',
    activity: 'Activitat',
    is_public: 'Publicar-la a la web d\'entitat',
    is_public_filter: 'Publicades a web',
    show_fac: 'Publicar-la a la web de la FAC',
    show_fac_filter: 'Publicades a FAC',
    repetitive: 'Generar repeticions',
    repetitive_limit: 'Finalitza el dia',
    repetitive_mode_edition_title_save: 'Edita l\'esdeveniment periòdic',
    repetitive_mode_edition_title_delete: 'Esborrar l\'esdeveniment periòdic',
    repetitive_mode_edition_only_one: 'Aquest esdeveniment',
    repetitive_mode_edition_only_future: 'Aquest esdeveniment i els següents',
    repetitive_mode_edition_all: 'Tots els esdeveniments',
    repetitive_mode_edition_save: 'Desar',
    repetitive_mode_edition_delete: 'Esborrar',
    repetitive_mode_edition_delete_confirmation: 'Segur que vols esborrar els esdeveniments?',
    repetitive_mode_edition_delete_confirmation_2: 'Estàs segur/a que vols esborrar els esdeveniments?',
    repetitive_mode_edition_cancel: 'Cancel·lar',
    overlapable_title: 'Superposició d\'esdeveniments',
    overlapable_ask: 'Vols permetre la celebració de diversos esdeveniments en el mateix espai i temps?',
    data: 'Dades',
    images: 'Imatges',
    images_desc: '* Pots marcar les imatges que s\'enviaran en el correu electrònic d\'invitació. Tingues en compte de reduir al màxim el pes del arxius.',
    docs: 'Documents',
    docs_desc: '* Pots marcar els documents que s\'enviaran en el correu electrònic d\'invitació. Tingues en compte de reduir al màxim el pes del arxius.',
    docs_mark: 'Adjunt a correu d\'invitació (*)',
    bookings: 'Persones',
    exporter_file_name: 'activitats',
    exporter_header_id: 'Identificador intern',
    exporter_header_title: 'Esdeveniment',
    exporter_header_date_start: 'Data inicial',
    exporter_header_date_finish: 'Data final',
    exporter_header_classrooms_name: 'Espai',
    exporter_header_activities_name: 'Activitat',
    exporter_header_is_public: 'És públic',
    export_title: 'Exportar activitats',
    export_form_text: 'Data d\'inici',
    export_form_from: 'Des del',
    export_form_to: 'Fins el',
    pdf_filename: 'esdeveniments',
    organizer: 'Organitzador',
    address: 'Adreça de l\'esdeveniment',
    city: 'Població',
    alert_days: 'Avís als administradors',
    alert_days_none: 'Sense avís',
    alert_days_0: 'El mateix dia',
    alert_days_1: '1 dia abans',
    alert_days_2: '2 dies abans',
    alert_days_3: '3 dies abans',
    alert_days_4: '4 dies abans',
    alert_days_5: '5 dies abans',
    alert_days_6: '6 dies abans',
    alert_days_7: '7 dies abans',
    alert_days_8: '8 dies abans',
    alert_days_9: '9 dies abans',
    alert_days_10: '10 dies abans',
    alert_days_11: '11 dies abans',
    alert_days_12: '12 dies abans',
    alert_days_13: '13 dies abans',
    alert_days_14: '14 dies abans',
    tickets_url: 'URL venda d\'entrades',
  },
  public_events: {
    not_actived: 'L\'entitat no té l\'agenda activa',
    association_not_found : 'L\'entitat no s\'ha trobat o hi ha hagut un error',
    event_not_found : 'L\'activitat no s\'ha trobat o hi ha hagut un error',
    view_web: 'Agenda web',
    go_back: 'Tornar enrere',
    search: 'Cercar',
    filter_date_after: 'Comença després de',
    filter_date_before: 'Comença abans de',
    filter_activity: 'Activitat',
    filter_organizer: 'Organitzador',
    filter_province: 'Província',
    filter_region: 'Comarca',
    filter_city: 'Població',
    filter_organizer_all: 'Totes les entitats',
    filter_activity_all: 'Totes les activitats',
    filter_province_all: 'Totes les províncies',
    filter_region_all: 'Totes les comarques',
    filter_city_all: 'Totes les poblacions',
    more_info: '+ Info',
    tickets: 'Entrades',
  },
  recercat_centers:
    {
      public_id: 'Codi',
      name: 'Nom',
      city: 'Població',
      email: 'Correu electrònic',
      actived: 'Actiu',
      create: 'Afegir centre',
      no_centers_title : 'centres',
      no_centers_ask : 'Vols afegir-ne un?',
      column_center: 'Centre',      
      column_city: 'Població (seu de l\'entitat)',      
      actions: 'Accions',
      actions_revoke: 'Revocar',
      actions_validate: 'Validar', 
      actions_deny: 'Denegar', 
      actions_title0: 'Revocar carnet',    
      actions_title1: 'Validar sol·licitud de carnet',    
      actions_title2: 'Denegar sol·licitud de carnet',
      actions_title3: 'Validar sol·licitud de carnet',
      actions_title4: 'Revocar carnet',
      actions_title5: 'Denegar sol·licitud de carnet',
      actions_title6: 'Validar sol·licitud de carnet',        
      action_button0: 'Revocar',
      action_button1: 'Validar',
      action_button2: 'Denegar',
      action_button3: 'Validar',
      action_button4: 'Revocar',
      action_button5: 'Denegar',
      action_button6: 'Validar',        
      validate_num_member: 'Número de carnet',    
      validate_send_email: 'Enviar el carnet digital a la persona',    
      revoke_send_email: 'Enviar missatge de confirmació a la persona',
      revoke_title: 'Revocar carnet',    
      revoke_ask: 'Segur que vols revocar el carnet? S\'esborraran les dades personals i no es podran recuperar.',    
      deny_ask: 'Segur que vols denegar la sol·licitud de carnet? S\'esborraran les dades personals i no es podran recuperar.',    
      revoke_comment: 'Motiu',    
      revoke_button: 'Revocar',        
      resume_send: 'Enviament de resum',
      resume_send_title: 'Enviament de resum',
      resume_send_ask: 'Segur que vols enviar un resum dels carnets al centre?' ,  
      resume_send_ask_again: 'Ja s\'ha enviat anteriorment un resum dels carnets al centre. Segur que vols tornar a enviar-lo?',
      resume_send_ok: 'Enviament realitzat!',
      resume_send_tooltip_button: 'Enviar resum de carnets',
      resume_send_send_tooltip_sending: 'S\'està enviant el resum de carnets...',
      resume_send_tooltip_done: 'S\'ha enviat el resum de carnets',
      resume_send_ko: 'El resum de carnets no s\'ha pogut entregar al destinatari',
        
      dashboard_resume_title: 'Estat de carnets',
      dashboard_resume_num_members: 'Carnets actius',
      dashboard_resume_num_centers: 'Total de centres actius',
      dashboard_graphic_title: 'Carnets validats',
      dashboard_graphic_cards: 'Carnets',
                
      validation_email_code_0: 'La vostra sol·licitud per obtenir el Carnet digital Recercat ha estat acceptada correctament.<br/><br />Properament us enviarem el Carnet digital Recercat a la vostra adreça electrònica.<br /><br />Reviseu la carpeta de correu brossa de la vostra bústia.<br /><br />',
      validation_email_code_1: 'El codi de l\'adreça no és correcta',
      validation_email_code_2: 'La sol·licitud no s\'ha trobat o ja no està activa',
      validation_email_code_3: 'La teva sol·licitud ja està en curs.',
      validation_email_code_4: 'L\'entitat de l\'adreça no és correcta.',
      validation_email_code_5: 'El formulari de sol·licitud de carnets Recercat està desactivat.',
      validation_email_code_6: 'L\'enllaç ja no és vàlid. Si us plau, torneu a fer la sol·licitud.',
      validation_email_error: 'S\'ha produït un error intentant validar la sol·licitud',
        
      form_title: 'Formulari de sol·licitud del Carnet digital Recercat',
      form_title_send_email: 'Formulari de sol·licitud del Carnet digital Recercat',
      form_title_validate_ok: 'Confirmació de sol·licitud del carnet Recercat',
      form_title_validate_ko: 'Sol·licitud del carnet Recercat',
      required_fields: '* Tots els camps són necessaris',        
        
      resume_title: 'Estat dels carnets Recercat',
      resume_pending_center: 'Pendents de validació centre',
      resume_pending_irmu: 'Pendents de validació per l\'IRMU',
      resume_validated: 'Validats per l\'IRMU',
      resume_error: 'No s\'han pogut recuperar les dades, l\'adreça no és vàlida.',
      resume_actions_accept: 'Acceptar',
      resume_actions_deny: 'Denegar',
      resume_actions_revoke: 'Revocar',
      resume_actions_logout: 'Tancar sessió',
        
      resume_dialog_title_3: 'Acceptar sol·licitud de carnet',
      resume_dialog_desc_3: 'La sol·licitud continuarà el seu procés i passarà a l\'estat Pendent de Validació IRMU.',
      resume_dialog_button_3: 'Acceptar',
      resume_dialog_title_5: 'Denegar sol·licitud',
      resume_dialog_desc_5: 'Segur que vols denegar la sol·licitud de carnet? La persona rebrà un correu electrònic predefinit informant-lo de la denegació i s\'esborraran totes les seves dades.',
      resume_dialog_button_5: 'Denegar',
      resume_dialog_title_4: 'Revocar carnet',
      resume_dialog_desc_4: 'Segur que vols revocar el carnet? La persona rebrà un correu electrònic predefinit informant-lo de la baixa i s\'esborraran totes les seves dades.',
      resume_dialog_button_4: 'Revocar',
      resume_dialog_title_8: 'Tancar sessió',
      resume_dialog_desc_8: 'Segur que vols tancar la sessió? L\'enllaç deixarà de ser vàlid i només podràs tornar a accedir a aquesta pàgina si reps un nou correu d\'estat de carnets Recercat.',
      resume_dialog_button_8: 'Tancar sessió',
        
      delete_center_title: 'Segur que vols esborrar el centre? ',
      delete_center_ask: 'Es revocaran tots els seus carnets actuals, es denegaran les sol·licituds de carnet pendents i es devincularà el centre de qualsevol carnet en el passat. S\'enviarà una notificació a totes les persones afectades.',
        
    },
    
  documents_fac : {
    title: 'Documents de la FAC',
    description: 'En aquest apartat podeu consultar els documents disponibles per a les associacions federades.'
  },
    
  bookings: {
    create_user_button: 'Persona',
    create_user_title: 'Afegir persona',
    create_group_button: 'Grup',
    create_group_title: 'Afegir grup',
        
    status_0: 'Invitació',
    email_sent_0: 'Correu d\'invitació',
    email_sent_title_0: 'Correu d\'invitació',
    email_sent_ask_0: 'Segur que vols enviar-li el correu d\'invitació predefinit?' ,  
    email_sent_ask_all_0: 'Segur que vols enviar el correu d\'invitació predefinit a totes les persones que encara no s\'hagi enviat?' ,  
    email_sent_ask_again_0: 'Ja s\'ha enviat anteriorment el correu d\'invitació. Segur que vols tornar a enviar-li?',        
    email_send_all_button_0: 'Invitacions',
    email_send_all_button_title_0: 'Enviar invitacions',
    email_sent_ko_0: 'La invitació no s\'ha pogut entregar al destinatari',
    email_sent_tooltip_button_0: 'Enviar invitació',
    email_sent_tooltip_sending_0: 'S\'està enviant la invitació...',
    email_sent_tooltip_done_0: 'S\'ha enviat la invitació',
        
    status_1: 'Recordatori',
    email_sent_1: 'Correu de recordatori',
    email_sent_title_1: 'Correu de recordatori',
    email_sent_ask_1: 'Segur que vols enviar-li el correu de recordatori predefinit?' ,  
    email_sent_ask_all_1: 'Segur que vols enviar el correu de recordatori predefinit a totes les persones que encara no s\'hagi enviat?' ,  
    email_sent_ask_again_1: 'Ja s\'ha enviat anteriorment el correu de recordatori. Segur que vols tornar a enviar-li?',              
    email_send_all_button_1: 'Recordatoris',
    email_send_all_button_title_1: 'Enviar recordatoris',
    email_sent_ko_1: 'El recordatori no s\'ha pogut entregar al destinatari',
    email_sent_tooltip_button_1: 'Enviar recordatori',
    email_sent_tooltip_sending_1: 'S\'està enviant el recordatori...',
    email_sent_tooltip_done_1: 'S\'ha enviat el recordatori',
        
    email_sent_ok: 'Enviament realitzat!', 
    email_only_pending: 'A les persones que encara no s\'ha enviat',
    email_all: 'A totes les persones',
    email_send_button: 'Enviar',
    email_send_all_advise: 'L\'enviament massiu s\'executarà en segon pla i pot trigar uns minuts.',
            
    email_status_0: '',
    email_status_1: 'Enviant...',
    email_status_2: 'Error enviant!',
    email_status_3: 'Enviat!',
    email_status_4: 'Retornat!',
        
    delete_all_button: 'Esborrar',        
    delete_all_button_title: 'Esborrar persones',
    delete_all_ask: 'Segur que vols esborrar totes les persones de l\'esdeveniment?' ,  
    reminder: 'Enviar recordatori a totes les persones',
    reminder_0: 'No enviar',
    reminder_1: 'El mateix dia a les 7h',
    reminder_2: 'El mateix dia a les 8h',
    reminder_3: 'El mateix dia a les 9h',
    reminder_4: 'El mateix dia a les 10h',  
    reminder_5: 'El dia anterior a les 19h',    
    reminder_6: 'El dia anterior a les 20h',        
    reminder_7: 'El dia anterior a les 21h',        
    reminder_8: 'El dia anterior a les 22h',  
        
    exporter_file_name: 'persones_esdeveniment',
    exporter_header_id : 'Identificador',
  },
  social_nets : {
    title : 'Xarxa social',
    link : 'Nom d\'usuari'
  },
  users_families : {
    name : 'Nom'
  },
  users_activities: {
    title: 'Activitats',
    create_button: 'Afegir',
    create: 'Afegir resum anual d\'activitats',
    edit: 'Modificar resum anual d\'activitats',
    name: 'Activitat',
    quantity: 'Quantitat',
    assistants: 'Assistents',
    adult: 'Públic',
    total_quantity: 'Núm. activitats',
    total_assistants: 'Total assistents',
  },
  users_trainings : {
    title0: 'Formació en arts',
    title1: 'Formacions',
    create_button: 'Afegir',
    create0: 'Afegir resum anual de formacions en arts',
    create1: 'Afegir resum anual de formacions',
    edit0: 'Modificar resum anual de formacions en arts',
    edit1: 'Modificar resum anual de formacions',        
    type: 'Formació',
    formal: 'Tipus',
    students: 'Estudiants',
    teachers_workers: 'Professorat remunerat',
    teachers_volunteers: 'Professorat voluntari',
    total_hours: 'Total d\'hores',
    total_quantity0: 'Núm. formacions en art',
    total_quantity1: 'Núm. formacions',
    total_teachers_workers: 'Total professorat remunerat',
    total_teachers_volunteers: 'Total professorat voluntari',
    total_students: 'Total estudiants',
    type0: 'Teatre',
    type1: 'Dansa',
    type2: 'Circ',
    type3: 'Coral',
    type4: 'Cant',
    type5: 'Instruments musicals',
    type6: 'Arts plàstiques',
    type7: 'Arts audiovisuals-digitals',
    type8: 'Escriptura i expressió literària',
    type9: 'Fotografia',
    type20: 'Idiomes',
    type21: 'Esportives - Altres',
    type22: 'Esportives - Futbol',
    type23: 'Esportives - Bàsquet',
    type24: 'Esportives - Tennis taula',
    type25: 'Esportives - Escacs',
    type26: 'Esportives - Excursionisme',
    type27: 'Curs Robòtica/Tecnologia',
    formal_art_0: 'Formal',
    formal_art_1: 'No formal',
    formal_0: 'Formal/federada',
    formal_1: 'No formal'    
  },  
  users_social_projects: {
    title: 'Projectes socials',
    create_button: "Afegir",
    create: 'Afegir resum anual de projectes socials',    
    edit: 'Modificar resum anual de projectes socials',
    type: "Tipus de destinatari",
    quantity: "Quantitat",
    total_quantity: "Quantitat total",
    type0: 'Migració',
    type1: 'Violència masclista',
    type2: 'Discapacitat',
    type3: 'Atur',
    type4: 'Famílies monoparentals',
    type5: 'Tercera edat (persones grans)',
    type6: 'Salut mental',
    type7: 'Inserció sociolaboral',
    type8: 'Altres'
  },
  users_sections: {
    title: 'Seccions',
    create_button: 'Afegir',
    create: 'Afegir resum anual de seccions',
    edit: 'Modificar resum anual de seccions',
    participants: 'Participants',
    num_sections: 'Núm. seccions',
    total_participants: 'Total participants',
    name: 'Secció',
    quantity: 'Quantitat',
    assistants: 'Assistents',
    total_quantity: 'Núm. activitats',
    total_assistants: 'Total assistents',
  },
  users_shows: {
    title: 'Espectacles',
    create_button: 'Afegir',
    create: 'Afegir resum anual d\'espectacles',
    edit: 'Modificar resum anual d\'espectacles',
    name: 'Espectacle',
    total_quantity: 'Núm. funcions',
    total_assistants: 'Total assistents',
    total_inversion: 'Total pressupost',
    type: 'Tipus',
    type_1: 'Teatre',
    type_2: 'Dansa',
    type_3: 'Música',
    type_4: 'Altres',
    amateur: 'Amateur/Professional',
    amateur_1: 'Amateur',
    amateur_2: 'Professional',
    adult: 'Públic',
    adult_1: 'Infantil',
    adult_2: 'Adult',
    adult_3: 'Per a tots els públics',
    quantity: 'Núm. funcions',
    assistants: 'Assistents',
    inversion: 'Pressupost',
    occupancy: 'Ocupació (%)',        
  },
  check_members: {
    not_found: 'El carnet no és vàlid',
    status_1: 'Vàlid',
    status_2: 'No vàlid',
    status_3: 'No vàlid',
    checker_name: 'Nom i cognoms',
    checker_num_member: 'Número',
    checker_date: 'Data de validació',
  },
  users_annuary : {
    tab: 'Anuari',   
    title: 'Formulari per a l\'anuari',        
    answered_status: 'Respost',
    answered_status_0: 'No',
    answered_status_1: 'Sí',
    answered_date_time: 'Data de resposta',
    expiration_date: 'Data límit per a respondre',
    sent_status: 'Enviament',
    reminder_status: 'Recordatori'  ,
    confirm_status: 'Confirmació'  ,
    send: 'Enviar',
    create_button: 'Enviar nou formulari',
    edit: 'Editar formulari',
    send_dialog_button: 'Formulari anuari',
    send_dialog_action: 'Acció a realitzar',
    send_dialog_action_0: 'Enviar enllaç del formulari a tots els contactes',
    send_dialog_action_1: 'Enviar recordatori amb l\'enllaç al formulari a tots els contactes',        
    send_dialog_text1_0: 'S\'enviarà un missatge predefinit a tots els contactes del grup als quals encara no s\'hagi enviat amb l\'enllaç per a respondre el formulari.',
    send_dialog_text1_1: 'S\'enviarà un recordatori amb l\'enllaç del formulari a tots els membres del grup que ja s\'hagi enviat i encara no hagin respost.',
    send_dialog_text2: 'La data límit per a respondre el formulari serà la que indiquis a continuació.',
    send_dialog_text3: 'L\'enviament s\'executarà en segon pla i pot trigar uns minuts. Podràs consultar l\'estat de l\'enviament i de les repostes a la fitxa de cada contacte o usant els filtres del llistat de contactes.',
        
    not_found: 'El formulari no s\'ha trobat o no està disponible',
    expired: 'S\'ha acabat el termini per a respondre el formulari, si us plau, posa\'t en contacte amb la FAC.',
        
    email_sent_0: 'Correu amb el formulari',
    email_sent_title_0: 'Correu amb enllaç al formulari',
    email_sent_ask_0: 'Segur que vols enviar-li l\'enllaç al formulari?' ,   
    email_sent_ask_again_0: 'Ja s\'ha enviat anteriorment el correu amb l\'enllaç al formulari. Segur que vols tornar a enviar-li? Es generarà un nou enllaç.',   
    email_sent_ko_0: 'El formulari no s\'ha pogut entregar al destinatari',
    email_sent_tooltip_button_0: 'Enviar formulari',
    email_sent_tooltip_sending_0: 'S\'està enviant la el formulari...',
    email_sent_tooltip_done_0: 'S\'ha enviat el formulari',
        
    email_sent_1: 'Correu de recordatori',
    email_sent_title_1: 'Correu de recordatori',
    email_sent_ask_1: 'Segur que vols enviar-li el correu de recordatori predefinit?' ,  
    email_sent_ask_again_1: 'Ja s\'ha enviat anteriorment el correu de recordatori. Segur que vols tornar a enviar-lo?', 
    email_sent_ko_1: 'El recordatori no s\'ha pogut entregar al destinatari',
    email_sent_tooltip_button_1: 'Enviar recordatori',
    email_sent_tooltip_sending_1: 'S\'està enviant el recordatori...',
    email_sent_tooltip_done_1: 'S\'ha enviat el recordatori',
        
    email_sent_2: 'Correu de confirmació',
    email_sent_title_2: 'Correu de confirmació',
    email_sent_ask_2: 'Segur que vols enviar-li el correu de confirmació predefinit?' ,  
    email_sent_ask_again_2: 'Ja s\'ha enviat anteriorment el correu de confirmació. Segur que vols tornar a enviar-lo?', 
    email_sent_ko_2: 'El correu de confirmació no s\'ha pogut entregar al destinatari',
    email_sent_tooltip_button_2: 'Enviar confirmació',
    email_sent_tooltip_sending_2: 'S\'està enviant el correu de confirmació...',
    email_sent_tooltip_done_2: 'S\'ha enviat el correu de confirmació',
        
    form_title: 'Formulari per a l\'anuari %year%',
    form_send: 'Enviar',
    form_already_answered: 'El formulari ja ha estat respost, si voleu modificar les vostres dades, si us plau, poseu-vos en contacte amb la FAC.',
    form_general_data: 'Dades generals de l\'entitat',
    form_activities: 'Activitats',
    form_trainings_art: 'Formacions en art',
    form_trainings: 'Formacions',
    form_classrooms: 'Espais',
    form_social_nets: 'L\'entitat a la xarxa',
    form_social_nets_title: 'Xarxes socials',
    form_economic_data: 'Dades econòmiques',
    form_economic_data_incomings: 'Ingressos',
    form_economic_data_expenses: 'Despeses',
    form_economic_data_inversion: 'Inversió',
    form_shows: 'Espectacles',
    form_sections: 'Seccions de l\'entitat',
    form_workers: 'Personal remunerat',
    form_volunteers: 'Voluntaris',
    form_edited_books_books: 'Llibre de l\'ateneu',
    form_edited_books_newsletters: 'Butlletí electrònic',
    form_edited_books_magazines: 'Revista',
    form_entity: 'Nom de l\'entitat',
        
    form_desc_activities: 'Les activitats que es desenvolupen al vostre ateneu. Diferents a les formacions i cursos.',
    form_desc_classrooms : 'Els espais físics o equipaments que disposeu a l\'ateneu.',
    form_desc_shows: 'Els actes que es representen a l’ateneu.',
    form_desc_sections: 'Grups de l’entitat.',
    form_desc_trainings_art: 'Cursos amb una finalitat formativa o d\'ensenyament en arts que es desenvolupen a la seva entitat.',
    form_desc_trainings: 'Cursos amb una finalitat formativa o d\'ensenyament que es desenvolupen a la seva entitat.',
    form_desc_workers: 'Persones contractades que reben un salari a la vostre entitat.',
    form_desc_volunteers: 'Persones que ofereixen el seu temps, experiència, coneixements i treball per a la vostra entitat sense rebre una compensació econòmica.',
    form_desc_social_nets: 'La vostra presència en les xarxes socials digitals.',
    form_desc_economic_data: 'Les dades generals de les despeses, ingressos i inversions generals que s\'han fet a l\'ateneu.',
    form_desc_edited_books_books: 'Llibres publicats des de la vostra entitat.',
    form_desc_edited_books_newsletters: 'Tipus de publicacions periòdiques o que publiqueu de manera regular amb les notícies del vostre ateneu.',
    form_desc_edited_books_magazines: 'Tipus de publicació periòdica amb articles de informació general o determinada del vostre ateneu.'
                
  },
  users_economic_data : {
    incomings: 'Ingressos',
    expenses: 'Despeses',
    total: 'Resultat',
    inversion: 'Inversió en obres',
    members : 'Quotes de socis',
    services : 'Serveis oferts',
    subsidies : 'Subvencions (Generalitat, Diputació, Ajuntament o altres)',
    rentals : 'Arrendaments',
    products : 'Venda de productes',
    privates : 'Ajuts privats',
    others : 'Altres',
    create: 'Afegir pressupost anual',
    create_button: 'Afegir',
    edit_title: 'Modificar pressupost anual',
    exp_rentals: 'Lloguers',
    exp_workers: 'Personal',
    exp_material: 'Material',
    exp_professionals: 'Professionals externs',
    exp_groups: 'Catxés de grups',
    exp_supplies: 'Subministraments',
    exp_maintenance: 'Manteniment',
    exp_marketing: 'Comunicació i publicitat',
    exp_others: 'Altres',
    avg_quote: 'Mitjana de la quota de soci/a anual',
    detail: 'Detall',
    inv_building: 'A l\'edifici en general',
    inv_theatre: 'Al teatre',
    inv_bar: 'Al bar',
    inv_accessibility: 'Accessibilitat',
    inv_security: 'Seguretat',
    inv_others: 'Altres',        
        
  },
  
    users_demografy: {
        title: 'Dades demogràfiques',
        create: 'Afegir',      
        edit_title: 'Modificar dades demogràfiques',
        year: 'Any',
        num_members: 'Núm. de socis',
        avg_age: 'Mitjana d\'edat',
        avg_age_managers: 'Mitjana d\'edat junta directiva',
        percent_man: 'Homes (%)',
        percent_woman: 'Dones (%)',
        percent_no_binary: 'No binaris (%)'
    }, 

  users_workers : {
    create_0: 'Afegir resum anual del personal remunerat',
    create_1: 'Afegir resum anual del voluntariat',
    create_button: 'Afegir',
    edit_title_0: 'Modificar resum anual del personal remunerat',
    edit_title_1: 'Modificar resum anual del voluntariat',
    administration: 'Administració',
    activities: 'Tècnic d\'activitats',
    concierge: 'Consergeria',
    clean_up: 'Neteja',
    comunication : 'Comunicació',
    accounting : 'Comptabilitat',
    maintenance : 'Manteniment',
    management : 'Gerència',
    others : 'Altres'      ,
    total_0: 'Total personal remunerat',
    total_1: 'Total personal voluntariat'
  },
  settings :{
    data : 'Dades de l\'entitat',
    data_desc : 'Nom, correu-e, adreça, logotip...',
    ibans : 'Bancs',        
    ibans_desc : 'Configuració de comptes bancaris', 
    payment_systems : 'TPV', 
    payment_systems_desc : 'Configuració de passarel·les bancàries', 
    postal_labels : 'Etiquetes postals',        
    postal_labels_desc : 'Configuració del format de les etiquetes postals', 
    alerts: 'Alertes',
    alerts_desc: 'Configuració de les alertes',
    emails: 'Plantilles de correus electrònics',        
    emails_desc: 'Continguts dels correus electrònics que reben les persones',         
    users: 'Usuaris administradors',        
    users_desc: 'Persones i permissos d\'accés',    
    maintenance: 'Accions de Superadmin',        
    maintenance_desc: 'Processos de base de dades...',   
    profile: 'Perfil d\'usuari',        
    profile_desc: 'Canvi de contrasenya',        
    subscription : 'Subscripció' ,   
    subscription_desc : 'Canviar la subscripció' ,
    returns_tax: 'Comissió de devolució (€)',
    bank_sufix: 'Sufix ordenant',
        
    add_member: 'Sol·licitud d\'alta de socis des de la web',
    add_member_desc: 'Configuració del formulari d\'inscripció',
    add_member_actived: 'Permetre la inscripció de socis des de la web',
    add_member_logo: 'Mostrar la capçalera amb el logotip i les dades de l\'entitat en el formulari',
    add_member_bank_data: 'Demanar dades bancàries',
    add_member_notification: 'Enviar notificació a l\'entitat al rebre una sol·licitud',
    add_member_form_text: 'Text explicatiu del formulari (HTML)',
    add_member_lopd: 'Text legal sobre el tractament de les dades (HTML)',
    add_member_form_sent: 'Text de confirmació de formulari enviat (HTML)',
    add_member_help: 'Com mostrem el formulari a la nostra pàgina web?',    
    add_member_help_1: 'Afegeix el següent codi HTML a la pàgina on vulguis mostrar el formulari de sol·licitud de soci:',    
    add_member_view_form: 'Accedir al formulari' ,
        
    recercat_add_member: 'Formulari de sol·licitud del Carnet digital Recercat',
    recercat_add_member_desc: 'Configuració del formulari',
    recercat_add_member_actived: 'Formulari actiu',
    recercat_add_member_form_sent: 'Text de confirmació de formulari enviat',   
    recercat_add_member_help_1: 'Afegeix el següent codi HTML a la pàgina on vulguis mostrar el formulari de sol·licitud de carnet',  
                
    emails_subject : 'Tema',
    emails_content : 'Contingut del missatge',
    emails_message : 'Missatge',
    emails_attached : 'Arxius',   
    emails_copy : 'Copiar plantilles necessàries a les entitats',   
    emails_copy_desc : 'Es copiaran les plantilles de correus a aquelles entitats que encara no les tinguin. Útil per quan creem una nova plantilla. Si alguna entitat ja la té, no es sobrescriurà.',   
    emails_vars : 'Variables disponibles',
        
    ibans_copy : 'Crear registre de IBAN\'s',   
    ibans_copy_desc : 'Es copiaran els IBAN\'s actuals de totes les entitats, a una taula específica d\'IBAN\'s (si encara no s\'havia fet), per a poder treballar a partir d\'ara amb múltiples IBAN\'s per entitat.',   
    done : 'Acció realitzada',
    
    backup : 'Crear còpia de seguretat',   
    backup_desc : 'Es realitza una còpia de seguretat de la base de dades en aquest moment.',   
        
    remittances_subscriptions: 'Crear subscripció a Rebuts per a totes les entitats',
    remittances_subscriptions_desc: 'Es crearà una subscripció a Rebuts de totes les entitats que tinguin la subscripció Gestió d\'entitats, sempre i quan no la tinguin ja, i no siguin l\'entitat Recercat.',
    
    sa_postal_labels: 'Crea configuració d\'etiquetes postals per a totes les entitats',
    sa_postal_labels_desc: 'Es crearà una configuració per defecte per a totes les entitats que encara no tinguin cap configuració d\'etiquetes configurada. ',
        
    emails_WELCOME : 'Correu de benvinguda de soci/a',
    emails_WELCOME_desc : 'Plantilla per defecte per als nous clients',
    emails_WELCOME_vars : '%nom_entitat% | %nom% | %nom_complet% | %num_soci% | %data_ingres%',
        
    emails_CLASSROOM_REQUEST : 'SISTEMA - Notificació de que s\'ha rebut una sol·licitud d\'espai',
    emails_CLASSROOM_REQUEST_desc : 'Correu que reben les entitats quan hi ha una sol·licitud d\'ús d\'espai',
    emails_CLASSROOM_REQUEST_vars : '%back_url% | %classroom_name% | %date_request% | %event_date% | %event_duration% | %event_recurrency% | %event_name% | %event_description% | %event_resources% | %comments% | %first_name% | %last_name_1% | %last_name_2% | %email% | %phone%',
        
    emails_ADDMEMBER_REQUEST : 'SISTEMA - Notificació de que s\'ha rebut una sol·licitud de soci/a',
    emails_ADDMEMBER_REQUEST_desc : 'Correu que reben les entitats quan hi ha una sol·licitud d\'alta de soci',
    emails_ADDMEMBER_REQUEST_vars : '%back_url%',
        
    emails_UNDELIVERED_EMAILS : 'SISTEMA - Notificació de correus no enviats /a',
    emails_UNDELIVERED_EMAILS_desc : 'Correu que reben les entitats quan alguns correus no s\'han pogut enviar',
    emails_UNDELIVERED_EMAILS_vars : '%emails_list%',
        
    emails_RESET_PSWD : 'SISTEMA - Notificació de recuperació de contrasenya',
    emails_RESET_PSWD_desc : 'Correu que reben els usuaris administradors si obliden la contrasenya',
    emails_RESET_PSWD_vars : '%reset_link%',
                
    emails_RECEIVE_RETURNED : 'Correu de rebut retornat (banc)',
    emails_RECEIVE_RETURNED_desc : 'Plantilla per defecte per als nous clients',
    emails_RECEIVE_RETURNED_vars : '%nom% | %email_entitat%',
        
    emails_RECEIVE_RETURNED_CASH : 'Correu de rebut impagat (efectiu)',
    emails_RECEIVE_RETURNED_CASH_desc : 'Plantilla per defecte per als nous clients',
    emails_RECEIVE_RETURNED_CASH_vars : '%nom% | %email_entitat%',
        
    emails_EVENT_INVITE : 'Correu d\'invitació a esdeveniment',
    emails_EVENT_INVITE_desc : 'Plantilla per defecte per als nous clients',
    emails_EVENT_INVITE_vars : '%nom% | %nom_esdeveniment% | %activitat% | %data_ini% | %espai% | %email_entitat% | %url_event%',
        
    emails_EVENT_REMINDER : 'Correu de recordatori d\'esdeveniment',
    emails_EVENT_REMINDER_desc : 'Plantilla per defecte per als nous clients',
    emails_EVENT_REMINDER_vars : '%nom% | %nom_esdeveniment% | %tipus% | %data_ini% | %data_fi% | %espai% | %url_event%',
        
    emails_ANNUARY_SEND : 'Correu amb l\'enllaç al formulari de l\'anuari',
    emails_ANNUARY_SEND_desc : 'Plantilla per defecte per als nous clients',
    emails_ANNUARY_SEND_vars : '%nom% | %year% | %date_expiration% | %link%',
        
    emails_ANNUARY_REMINDER : 'Correu de recordatori amb l\'enllaç al formulari de l\'anuari',
    emails_ANNUARY_REMINDER_desc : 'Plantilla per defecte per als nous clients',
    emails_ANNUARY_REMINDER_vars :  '%nom% | %year% | %date_expiration% | %link%',
        
    emails_ANNUARY_CONFIRM : 'Correu de confirmació amb l\'enllaç a les dades introduïdes',
    emails_ANNUARY_CONFIRM_desc : 'Plantilla per defecte per als nous clients',
    emails_ANNUARY_CONFIRM_vars :  '%nom% | %year% | %date_expiration% | %link%',
        
    emails_DIGITAL_CARD_SEND : 'Correu d\'entrega del carnet digital',
    emails_DIGITAL_CARD_SEND_desc : 'Plantilla per defecte per als nous clients',
    emails_DIGITAL_CARD_SEND_vars :  '%nom% | %nom_complet% | %nom_entitat% | %nom_grup% | %num_membre% | %google_wallet_link% | %google_wallet_image_and_link% | %apple_wallet_link% | %apple_wallet_image_and_link%',
    
    emails_RECERCAT_USER_VALIDATE_EMAIL : 'Correu de validació de la sol·licitud web',
    emails_RECERCAT_USER_VALIDATE_EMAIL_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_USER_VALIDATE_EMAIL_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_USER_REQUEST_OK : 'Correu de sol·licitud acceptada',
    emails_RECERCAT_USER_REQUEST_OK_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_USER_REQUEST_OK_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_USER_REQUEST_KO : 'Correu de de sol·licitud errònia',
    emails_RECERCAT_USER_REQUEST_KO_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_USER_REQUEST_KO_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED : 'Correu de revocació de carnet degut a centre eliminat',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED_vars : '%nom_entitat% | %nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %link%',
        
    emails_RECERCAT_IRMU_RESUME : 'Correu de resum (IRMU)',
    emails_RECERCAT_IRMU_RESUME_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_IRMU_RESUME_vars : '%nom_entitat% | %link% | %num_validats% | %num_pendents_irmu% | %num_pendents_centre%',
        
    emails_RECERCAT_CENTER_RESUME : 'Correu de resum (centre)',
    emails_RECERCAT_CENTER_RESUME_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_CENTER_RESUME_vars : '%nom_entitat% | %nom_centre% | %num_membre% | %link% | %num_validats% | %num_pendents_irmu% | %num_pendents_centre%',
        
    emails_RECERCAT_REQUEST_DENIED_BY_IRMU : 'Correu de sol·licitud denegada des de l\'IRMU',
    emails_RECERCAT_REQUEST_DENIED_BY_IRMU_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_REQUEST_DENIED_BY_IRMU_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre%',
        
    emails_RECERCAT_REQUEST_DENIED_BY_CENTER : 'Correu de sol·licitud denegada des del centre',
    emails_RECERCAT_REQUEST_DENIED_BY_CENTER_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_REQUEST_DENIED_BY_CENTER_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre%',
        
    emails_RECERCAT_CARD_REVOKED_BY_IRMU : 'Correu de carnet revocat des de l\'IRMU',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_CARD_REVOKED_BY_IRMU_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %num_membre%',
        
    emails_RECERCAT_CARD_REVOKED_BY_CENTER : 'Correu de carnet revocat des del centre',
    emails_RECERCAT_CARD_REVOKED_BY_CENTER_desc : 'Plantilla per defecte per als nous clients',
    emails_RECERCAT_CARD_REVOKED_BY_CENTER_vars : '%nom% | %nom_complet% | %nom_entitat% | %nom_centre% | %num_membre%',
                
    emails_ANNUARY_FORM_ANSWERED : 'FEDERACIÓ - Correu de notificació de resposta de formulari de l\'anuari',
    emails_ANNUARY_FORM_ANSWERED_desc : 'Només el rep la FAC',
    emails_ANNUARY_FORM_ANSWERED_vars :  '%name% | %year%',
        
    emails_ANNUARY_FORM_NUM_MEMBERS : 'FEDERACIÓ - Correu de notificació de canvi de número de socis',
    emails_ANNUARY_FORM_NUM_MEMBERS_desc : 'Només el rep la FAC',
    emails_ANNUARY_FORM_NUM_MEMBERS_vars :  '%nom% | %year% | %old_num_members% | %new_num_members%',
    
    emails_DONATIONS_USERS_CONFIRMATION : 'Donatius: Correu de confirmació d\'una donació',
    emails_DONATIONS_USERS_CONFIRMATION_desc : 'El rep el donant automàticament en el moment de fer la donació a través de la web',
    emails_DONATIONS_USERS_CONFIRMATION_vars :  '%nom% | %nom_complet% | %referencia% | %amount% | %nom_projecte% | %date_time% | %entitat_projecte%',
    
    emails_DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER : 'Donatius: Correu amb instruccions per a fer la transferència bancària',
    emails_DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER_desc : 'El rep el donant automàticament en el moment de fer la donació per transferència bancària',
    emails_DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER_vars :  '%nom% | %nom_complet% | %referencia% | %amount% | %bank_ordering% | %bank_iban% | %bank_swift% | %nom_projecte% | %date_time% | %entitat_projecte%',
    
    emails_DONATIONS_USERS_CERTIFICATE : 'Donatius: Correu d\'enviament del certificat de donació',
    emails_DONATIONS_USERS_CERTIFICATE_desc : 'El rep el donant',
    emails_DONATIONS_USERS_CERTIFICATE_vars : '%nom% | %nom_complet% | %referencia% | %amount% | %nom_projecte% | %date_time% | %entitat_projecte%',
    
    emails_DONATIONS_RESUME_ASSOCIATION : 'Donatius: Correu de resum dels donatius',
    emails_DONATIONS_RESUME_ASSOCIATION_desc : 'El reben setmanalment les entitats. Un correu per cada projecte de donatius actius',
    emails_DONATIONS_RESUME_ASSOCIATION_vars : '%project_name% | %amount% | %count_donations% | %sum_donations% | %sum_donations_percent% | %avg_donations% | %date_start% | %date_finish% | %if_target_by_date_ini% | %if_target_by_date_end%',
    
    emails_DONATIONS_RESUME_FAC : 'Donatius: Correu de resum dels donatius',
    emails_DONATIONS_RESUME_FAC_desc : 'El rep setmanalment la FAC, amb el resum de tots els projectes actius',
    emails_DONATIONS_RESUME_FAC_vars : '%iteration_project_ini% | %iteration_project_end% | %total_projects% | %total_sum_donations% | %total_count_donations% | %total_avg_donations% | %association_name% | %project_name% | %amount% | %count_donations% | %sum_donations% | %sum_donations_percent% | %avg_donations% | %date_start% | %date_finish% | %if_target_by_date_ini% | %if_target_by_date_end%',
      
    emails_DONATIONS_VALIDATE_REQUEST : 'Donatius: Correu de sol·licitud de validació',
    emails_DONATIONS_VALIDATE_REQUEST_desc : 'El rep la FAC quan l\'entitat demana de validar el projecte',
    emails_DONATIONS_VALIDATE_REQUEST_vars : '%association_name% | %project_name%',
    
    emails_DONATIONS_VALIDATE_ACCEPTED : 'Donatius: Correu de validació de projecte',
    emails_DONATIONS_VALIDATE_ACCEPTED_desc : 'El rep l\'entitat quan la FAC ha validat el projecte.',
    emails_DONATIONS_VALIDATE_ACCEPTED_vars : '%association_name% | %project_name%',
    
    emails_DONATIONS_VALIDATE_REJECTED : 'Donatius: Correu de denegació de validació de projecte',
    emails_DONATIONS_VALIDATE_REJECTED_desc : 'El rep l\'entitat quan la FAC ha rebutjat el projecte.',
    emails_DONATIONS_VALIDATE_REJECTED_vars : '%association_name% | %project_name%',
        
    emails_NEW_ADMIN : 'SISTEMA - Correu d\'invitació d\'administrador (primera vegada)',  
    emails_NEW_ADMIN_desc : 'El reben els nous administradors',  
    emails_NEW_ADMIN_vars : '%back_url% | %auth_user% | %auth_pswd% | %association_name%',  
    
    emails_NEW_ADMIN_PERMISSION : 'SISTEMA - Correu d\'invitació d\'administrador',  
    emails_NEW_ADMIN_PERMISSION_desc : 'El reben els administradors que ja tenen algun permís previ.',  
    emails_NEW_ADMIN_PERMISSION_vars : '%back_url% | %auth_user% | %association_name%',  
    
    emails_ASSOCIATIONS_TRIAL : 'SISTEMA - Correu de suport durant el període de prova',  
    emails_ASSOCIATIONS_TRIAL_desc : 'S\'envia manualment a les associacions durant el període de prova',  
    emails_ASSOCIATIONS_TRIAL_vars : '%nom% | %email%',  
    
    emails_ASSOCIATIONS_BEGIN : 'SISTEMA - Correu d\'inici de la subscripció',  
    emails_ASSOCIATIONS_BEGIN_desc : 'S\'envia manualment a les associacions',  
    emails_ASSOCIATIONS_BEGIN_vars : '%nom% | %email%',  
    
    emails_ASSOCIATIONS_END : 'SISTEMA - Correu de baixa de la subscripció',  
    emails_ASSOCIATIONS_END_desc : 'S\'envia manualment a les associacions',  
    emails_ASSOCIATIONS_END_vars : '%nom% | %email%',  
    
    emails_ALERT : 'SISTEMA - Alertes de les entitats',
    emails_ALERT_desc : 'Notificació d\'alerta',
    emails_ALERT_vars : '%association_name% | %message% | %date_alert%',
             
    emails_SKELETON : 'Esquelet de tots els correus',
    emails_SKELETON_desc : 'Plantilla per defecte per als nous clients',
    emails_SKELETON_vars : '%tema% | %contingut% | %nom_entitat% | %email_entitat%',
        
    emails_SA_SKELETON : 'SISTEMA - Esquelet de tots els correus',
    emails_SA_SKELETON_desc : 'Esquelet de tots els correus enviats des del sistema',
    emails_SA_SKELETON_vars : '%tema% | %contingut%',
        
    emails_headers : 'Capçalera',
    emails_headers_sender_name : 'Nom del remitent',
    emails_headers_sender_email : 'Correu electrònic del remitent',
    emails_headers_reply_to : 'Respondre a',
    emails_headers_cc : 'Cc',
    emails_headers_bcc : 'Bcc',
    emails_headers_vars : '%nom_entitat% | %email_entitat%', 
        
    classrooms_requests: 'Sol·licitud d\'ús d\'espai des de la web',
    classrooms_requests_desc: 'Configuració de la sol·licitud d\'espais',
    classrooms_requests_actived: 'Permetre la sol·licitud d\'ús des de la web',
    classrooms_requests_logo: 'Mostrar la capçalera amb el logotip i les dades de l\'entitat en el formulari',
    classrooms_requests_notification: 'Enviar notificació a l\'entitat al rebre una sol·licitud',
    classrooms_requests_text: 'Text a la capçalera de la pàgina de resum d\'espais',
    classrooms_requests_lopd: 'Text legal de política de protecció de dades',
    classrooms_requests_form_sent: 'Text de confirmació de formulari enviat',
    classrooms_requests_help: 'Com mostrem els espais a la nostra pàgina web?',    
    classrooms_requests_help_1: 'Afegeix el següent codi HTML a la pàgina on vulguis mostrar els espais de l\'entitat:',    
    classrooms_requests_view_form: 'Accedir a la web' ,
    
    donations_certificates: 'Certificat de donacions',
    donations_certificates_desc: 'Configuració del contingut del certificat de donació (HTML)',
    donations_certificates_donations_users_certificate: 'Contingut del certificat de donació',
    donations_certificates_donations_users_certificate_vars_title: 'Variables disponibles',
    donations_certificates_donations_users_certificate_vars: '%entitat_nom% | %entitat_address% | %entitat_zip% | %entitat_city% | %entitat_country% | %entitat_nif% | %entitat_email% | %entitat_firma% | %nom% | %cognom1% | %cognom2% | %email% | %nom_complet% | %nif% | %address% | %zip% | %phone% | %city% | %country% | %amount% | %date_time% | %nom_projecte% | %justificacio_projecte% | %data_certificat%',
    donations_certificates_header: 'Capçalera',
    donations_certificates_sign: 'Firma del responsable',
    donations_certificates_footer : 'Peu del certificat',
            
    annuary_form: 'Formulari per a realitzar l\'anuari',
    annuary_form_desc: 'Configuració del formulari per a realitzar l\'anuari',
    annuary_form_logo: 'Mostrar la capçalera amb el logotip i les dades de la FAC en el formulari',
    annuary_form_notification: 'Enviar notificació a la FAC al rebre una resposta',
    annuary_form_notification_association: 'Enviar notificació a l\'entitat al respondre el formulari amb un enllaç a les seves respostes',
    annuary_form_notification_num_members: 'Enviar notificació a la FAC al modificar el número de membres d\'una entitat',
    annuary_form_text: 'Text a la capçalera de la pàgina',
    annuary_form_lopd: 'Text legal de política de protecció de dades',
    annuary_form_sent: 'Text de confirmació de formulari enviat',
        
    templates_copied: 'S\'han copiat les plantilles a totes les entitats',
        
    profile_user_tab: 'Usuari',
    profile_pswd_tab: 'Contrasenya',
    profile_password: 'Nova contrasenya',
    profile_password_confirmation: 'Confirma contrasenya',
    profile_change_pswd_ok: 'S\'ha modificat la contrasenya d\'accés',
        
        
        
  },
  contacts : {
    title: 'Contactes de la FAC',
    description: 'En aquest apartat trobaràs una base de dades general i compartida per a tots els ateneus amb la finalitat d\'ajudar-vos a tenir actualitzats els contactes d\'administracions i contactes d\'interès. <br/>Us agrairem que si trobeu algun error ens ho feu saber per poder-lo corregir el més aviat possible.'
  },
  add_member : {
    association_not_found: 'No s\'ha trobat l\'entitat',
    form_not_actived: 'Disculpeu les molèsties, el formulari no està actiu.',
    form_title: 'Sol·licitud d\'alta de soci/a',
    recercat_form_title: 'Sol·licitud de carnet Recercat',
    recercat_association_not_found: 'La pàgina no s\'ha trobat',
    recercat_request_send : 'Enviar sol·licitud',
    form_lopd: 'Consento el tractament de les dades per a les finalitats descrites a l’enllaç següent: ',
    form_lopd_validator: 'Heu de consentir el tractament de les dades',        
    lopd: 'Tractament de les dades',
  },
  classrooms_requests : {
    association_not_found: 'No s\'ha trobat l\'entitat o l\'espai no està disponible',
    form_not_actived: 'No hi ha espais disponibles',
    form_title: 'Els nostres espais',
    form_request_title: 'Sol·licitud d\'ús de l\'espai',
    form_event_data_title: 'Dades de l\'activitat:',
    form_requester_data_title: 'Dades del sol·licitant:',
    form_event_date: 'Data i hora',
    form_event_duration: 'Durada (hores)',
    form_event_recurrency: 'És una activitat recurrent? Indica totes les dates.',
    form_event_name: 'Nom de l\'activitat',
    form_event_description: 'Descripció',
    form_event_resources: 'Recursos que es necessiten per a l\'activitat',
    form_event_comments: 'Altres comentaris',
    form_event_phone: 'Telèfon de contacte',        
    form_send: 'Sol·licitar',   
    form_doc: 'Veure fitxa tècnica',
    form_doc_title: 'fitxa_tècnica'
  },
  resources : {
    users : {
      name: 'Contactes'
    },
    events : {
      name: 'Activitats'
    },
    groups : {
      name: 'Grups'
    },
    real_state: {
      name: 'Immobles'      
    },
    agreements: {
      name: 'Convenis'
    },
    insurances: {
      name: 'Assegurances'
    }
  },
  'ra-tree' : {
    action : {
      add_root : 'Afegir grup',
    },
    new_node: 'Nou node',
    item_moved : 'S\'ha desplaçat el grup'
  },
  'ra-search' : {
    recent : 'Cerques recents'
  },


};

const messages = {
  ca: catalanMessages
};

const i18nProvider = polyglotI18nProvider(locale => messages['ca']);

export { useTranslate, i18nProvider };

