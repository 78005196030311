// ENVIRONMENT VARIABLES
//export const apiUrl = process.env.REACT_APP_API_URL;
export const projectUrl = (window.location.hostname === 'localhost' ? 'http://' : 'https://') + window.location.hostname + (window.location.port && window.location.port !== 80 ? (':' + window.location.port) : '');

export const apiUrl = window.location.hostname === 'localhost' ? 'http://localhost:81' :
  window.location.hostname === 'testentitats.sifac.cat' ? 'https://testentitats.sifac.cat:8443' :
    window.location.hostname === 'entitats.sifac.cat' ? 'https://entitats.sifac.cat:8443' :
      window.location.hostname === 'admin.gestioentitats.cat' ? 'https://api.gestioentitats.cat:8443' :
        'https://api.gestioentitats.cat:8443';

export const donationsUrl = window.location.hostname === 'localhost' ? 'http://localhost:3001' :
  window.location.hostname === 'testentitats.sifac.cat' ? 'https://testdonatius.sifac.cat' :
    window.location.hostname === 'entitats.sifac.cat' ? 'https://donatius.sifac.cat' :
      window.location.hostname === 'admin.gestioentitats.cat' ? 'https://donatius.gestioentitats.cat' :
        'https://donatius.sifac.cat';

// CONSTANTS
export const apiOrigin = 'http://localhost:3000';
export const apiDefaultLanguage = 'ca';
export const maxDocFileSize = 20000000; // API limitada a 25MB
export const maxImgFileSize = 5000000; // API limitada a 25MB

export const timeZone = 'Europe/Madrid'; 

export const targets = {
  ASSOCIATION : 0,
  GROUP : 1,
  USER : 2,
  MODULE : 3,
  EVENT : 4,
  ACTIVITY : 5,
  ACTIVITY_CATEGORY : 6,
  CLASSROOM_PHOTO : 7,
  USER_PHOTO : 8,
  TEMPLATE : 9,
  CLASSROOM_DOC: 10,
  USER_CLASSROOM_DOC: 11,
  USER_CLASSROOM_PHOTO: 12,
  GROUP_CARD: 13,
  DOCUMENT_FAC: 14,
  EVENT_IMAGE: 15,
  USER_MORE_PHOTOS : 16,
  REAL_STATE_DEED : 17,
  REAL_STATE_MAP : 18,
  REAL_STATE_ACTIVITY_CONTRACT : 19,
  REAL_STATE_ACTIVITY_INSURANCE : 20,
  REAL_STATE_PAYMENT : 21,
  AGREEMENT : 22,
  INSURANCE : 23,
  DONATION_PROJECT_IMAGE : 24,
  DONATION_CERTIFICATE_HEADER : 25,
  DONATION_CERTIFICATE_FOOTER : 26,
  DONATION_CERTIFICATE_SIGN : 27,
  GROUP_CARD_GOOGLE_WALLET: 28,
  GROUP_CARD_GOOGLE_WALLET_ICON: 29
};
        
export const targetsResources = [
  'settings',
  'groups-tree',
  'users',
  'modules',
  'events',
  'activities',
  'activities_categories',
  'classrooms',
  '',
  'settings-emails',
  'classrooms',
  'users_classrooms',
  'users_classrooms',
  'groups-tree',
  'docs_fac',
  'events',
  'users',
  'real_state',
  'real_state',
  'real_state_activities',
  'real_state_activities',
  'real_state_payments',
  'agreements',
  'insurances',
  'donations_projects',
  'settings',
  'settings',
  'settings',
  'users',
  'users',
];

export const targetsEmails = {
  NEWADMIN : 0,
  WELCOME : 1,
  CLASSROOMREQUEST : 2,
  ADDMEMBERREQUEST : 3,
  RECEIVERETURNED : 4,
  ALIVE : 5,
  EVENT_INVITE : 6,
  EVENT_REMINDER: 7, 
  UNDELIVERED_EMAILS : 8,
  ANNUARY_SEND : 9,
  ANNUARY_REMINDER: 10, 
  ANNUARY_FORM_ANSWERED: 11,
  ANNUARY_FORM_NUM_MEMBERS: 12,
  DIGITAL_CARD_SEND: 13,
  ANNUARY_CONFIRM: 14,
  RECERCAT_USER_VALIDATE_EMAIL : 15,
  RECERCAT_CENTER_RESUME : 17,
  RECERCAT_REQUEST_DENIED_BY_IRMU : 18,
  RECERCAT_REQUEST_DENIED_BY_CENTER : 19,
  RECERCAT_CARD_REVOKED_BY_IRMU : 20,
  RECERCAT_CARD_REVOKED_BY_CENTER : 21,
  RECERCAT_IRMU_RESUME : 22,
  RECERCAT_USER_REQUEST_OK: 23,
  RECERCAT_USER_REQUEST_KO: 24,
  RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED: 25,
  ALERT_PROCUREMENT: 26,
  RESET_PSWD: 27,
  NEWADMIN_PERMISSION: 28,
  DONATIONS_USERS_CERTIFICATE: 29 ,
  DONATIONS_USERS_CONFIRMATION: 30,
  DONATIONS_RESUME_ASSOCIATION: 31,
  DONATIONS_RESUME_FAC: 32,
  DONATIONS_VALIDATE_REQUEST: 33,
  DONATIONS_VALIDATE_ACCEPTED: 34,
  DONATIONS_VALIDATE_REJECTED: 35,
  ALERT: 36,
  DONATIONS_USERS_PRE_CONFIRMATION_TRANSFER: 37,
  ASSOCIATIONS_TRIAL: 38,
  ASSOCIATIONS_BEGIN: 39,
  ASSOCIATIONS_END: 40,
};
        
export const emailsStatus = {
  NOT_SENT: 0,
  SENDING_TO_SMTP: 1,
  SENDING_TO_SMTP_ERROR: 2,
  SENT_TO_SMTP_OK: 3,
  SENT_SERVER_ERROR: 4,    
};

export const usersEditedBooksType = {
  BOOKS: 1,
  BOOK_ENTITY: 2,
  NEWSLETTER: 3,
  MAGAZINE: 4,
  HIGHLIGHTED_DATES: 5,
  NOTABLE_INDIVIDUALS: 6,
  LINKS: 7
};

export const modules = {
  GESTIO : 'GESTIO',
  CONTACTS_FAC : 'CONTACTS_FAC',
  FEDERATION: 'FEDERATION',
  DIGITAL_CARDS: 'DIGITAL_CARDS',
  MEMBER_FAC: 'MEMBER_FAC',
  RECERCAT: 'RECERCAT',
  REAL_STATE: 'REAL_STATE',
  AGREEMENTS: 'AGREEMENTS',
  INSURANCES: 'INSURANCES',
  ALERTS: 'ALERTS',
  REPORTS: 'REPORTS',
  DOCUMENTS_FAC: 'DOCUMENTS_FAC',
  CALENDAR: 'CALENDAR',
  REMITTANCES: 'REMITTANCES',
  POSTAL_LABELS: 'POSTAL_LABELS',
  DONATIONS: 'DONATIONS',
  WALLETS: 'WALLETS',
};

export const modulesOptions = {	
  DOCUMENTS_FAC_R: 'DOCUMENTS_FAC_R',
  DOCUMENTS_FAC_W: 'DOCUMENTS_FAC_W',
  CALENDAR_STD: 'CALENDAR_STD',
  CALENDAR_FAC: 'CALENDAR_FAC',
  CALENDAR_FAC_PUBLISHERS: 'CALENDAR_FAC_PUBLISHERS',
  DONATIONS_FAC: 'DONATIONS_FAC',
  DONATIONS_OWN: 'DONATIONS_OWN',
};
    
export const recercatActions = {
  IRMU_REVOKE : 0,
  IRMU_VALIDATE : 1,
  IRMU_DENY : 2,
  CENTER_VALIDATE : 3,
  CENTER_REVOKE : 4,
  CENTER_DENY : 5,
  USER_VALIDATE : 6,
  USER_REQUEST: 7,
  CENTER_LOGOUT : 8,
};

export const choicesTypeUser = (translate) => [
  { id: 0, name: translate('users.user_type_0') },
  { id: 1, name: translate('users.user_type_1') }
];

export const choicesGenderUser = (translate) => [
  { id: 0, name: '' },
  { id: 1, name: translate('users.gender_1') },
  { id: 2, name: translate('users.gender_2') },
  { id: 3, name: translate('users.gender_3') }
];

export const choicesAlertsType = (translate) => [
    { id: 1, name: translate('alerts.type_1')},
    { id: 2, name: translate('alerts.type_2')},
    { id: 3, name: translate('alerts.type_3')},
    { id: 4, name: translate('alerts.type_4')},
    { id: 5, name: translate('alerts.type_5')},
    { id: 6, name: translate('alerts.type_6')},
    { id: 7, name: translate('alerts.type_7')},
    { id: 8, name: translate('alerts.type_8')},
    { id: 9, name: translate('alerts.type_9')}
];

export const choicesMonths = (translate) => [
  { id: 1, name: translate('common.months_1') },
  { id: 2, name: translate('common.months_2') },
  { id: 3, name: translate('common.months_3') },
  { id: 4, name: translate('common.months_4') },
  { id: 5, name: translate('common.months_5') },
  { id: 6, name: translate('common.months_6') },
  { id: 7, name: translate('common.months_7') },
  { id: 8, name: translate('common.months_8') },
  { id: 9, name: translate('common.months_9') },
  { id: 10, name: translate('common.months_10') },
  { id: 11, name: translate('common.months_11') },
  { id: 12, name: translate('common.months_12') },
];

export const choicesDays = (translate) => {
  const months = [];
  for (let i = 1; i <= 31; i++) {
    months.push({ id: i, name: i });
  }
  return months;
};

export const choicesDelegations = [
  { id: 4, name: 'DT Barcelonès' },
  { id: 5, name: 'DT Baix Llobregat' } ,
  { id: 6, name: 'DT Vallesos - Maresme' },
  { id: 1, name: 'DT Girona' } ,
  { id: 7, name: 'DT Catalunya Central' },
  { id: 8, name: 'DT Penedès' },
  { id: 2, name: 'DT Camp de Tarragona - Terres de l\'Ebre' },
  { id: 3, name: 'DT Ponent - Alt Pirineu' }
];

export const choicesProperty = [
  { id: 1, name: 'Ajuntament' } ,
  { id: 2, name: 'Privat' },
  { id: 3, name: 'Església' },
  { id: 4, name: 'Generalitat' },
  { id: 5, name: 'Estat' }
];

export const choicesCession = [                        
  { id: 1, name: 'Ocupat' } ,
  { id: 2, name: 'Llogat' },
  { id: 3, name: 'Cedit' }
];

export const choicesClassroomsType = (translate) => 
  [
    { id: 0, name: translate('classrooms.type0')  },
    { id: 1, name: translate('classrooms.type1')  },
    { id: 2, name: translate('classrooms.type2')  },
    { id: 3, name: translate('classrooms.type3')  },
    { id: 4, name: translate('classrooms.type4')  },
    { id: 5, name: translate('classrooms.type5')  },
    { id: 6, name: translate('classrooms.type6')  },
    { id: 7, name: translate('classrooms.type7')  },
    { id: 8, name: translate('classrooms.type8')  },
    { id: 9, name: translate('classrooms.type9')  },
    { id: 10, name: translate('classrooms.type10')  }
  ];
  
export const choicesDonationsUsersPaymentMethod = (translate) => 
  [
    { id: 0, name: translate('donations_users.payment_method_0')  },
    { id: 1, name: translate('donations_users.payment_method_1')  },
    { id: 2, name: translate('donations_users.payment_method_2')  }
  ];

export const choicesDonationsUsersPublicPaymentMethod = (translate) => 
  [    
    { id: 1, name: translate('donations_users.payment_method_1')  }
  ];

export const choicesDonationsUsersStatus = (translate) => 
[    
  { id: 0, name: translate('donations_users.status_0')  },
  { id: 1, name: translate('donations_users.status_1')  },
  { id: 2, name: translate('donations_users.status_2')  }
];

export const choicesRealStateUse = (translate) => 
  [    
    { id: 1, name: translate('real_state.use_1')  },
    { id: 2, name: translate('real_state.use_2')  },
    { id: 0, name: translate('real_state.use_0')  }
  ];

export const choicesDonationsProjectsFacValidationStatus = (translate) =>
[
    { id: 0, name: translate('donations_projects.fac_validation_status_0')  },
    { id: 1, name: translate('donations_projects.fac_validation_status_1')  },
    { id: 2, name: translate('donations_projects.fac_validation_status_2')  },
    { id: 3, name: translate('donations_projects.fac_validation_status_3')  }
];

export const choicesRealStateActivitiesType = (translate) => 
  [    
    { id: 1, name: translate('real_state_activities.type_1')  },
    { id: 2, name: translate('real_state_activities.type_2')  },
    { id: 0, name: translate('real_state_activities.type_0')  }
  ];

export const choicesRealStateActivitiesInsuranceWho = (translate) => 
  [    
    { id: 0, name: translate('real_state_activities.insurance_who_0')  },
    { id: 1, name: translate('real_state_activities.insurance_who_1')  }
  ];

export const choicesRealStatePaymentsType = (translate) => 
  [    
    { id: 1, name: translate('real_state_payments.type_1')  },
    { id: 2, name: translate('real_state_payments.type_2')  },
    { id: 3, name: translate('real_state_payments.type_3')  },
    { id: 4, name: translate('real_state_payments.type_4')  },
    { id: 5, name: translate('real_state_payments.type_5')  },
    { id: 6, name: translate('real_state_payments.type_6')  },
    { id: 7, name: translate('real_state_payments.type_7')  },
    { id: 8, name: translate('real_state_payments.type_8')  },
    { id: 9, name: translate('real_state_payments.type_9')  },
    { id: 0, name: translate('real_state_payments.type_0')  }
  ];
  
export const choicesRealStatePaymentsPayer = (translate) => 
  [    
    { id: 1, name: translate('real_state_payments.payer_1')  },
    { id: 2, name: translate('real_state_payments.payer_2')  },
    { id: 3, name: translate('real_state_payments.payer_3')  },
    { id: 0, name: translate('real_state_payments.payer_0')  }
  ];
  
export const choicesTrainingsType0 = (translate) => 
  [
    { id: 0, name: translate('users_trainings.type0')  },
    { id: 1, name: translate('users_trainings.type1')  },
    { id: 2, name: translate('users_trainings.type2')  },
    { id: 3, name: translate('users_trainings.type3')  },
    { id: 4, name: translate('users_trainings.type4')  },
    { id: 5, name: translate('users_trainings.type5')  },
    { id: 6, name: translate('users_trainings.type6')  },
    { id: 7, name: translate('users_trainings.type7')  },
    { id: 8, name: translate('users_trainings.type8')  },
    { id: 9, name: translate('users_trainings.type9')  }
  ];

export const choicesTrainingsType1 = (translate) => 
  [
    { id: 20, name: translate('users_trainings.type20')  },    
    { id: 22, name: translate('users_trainings.type22')  },
    { id: 23, name: translate('users_trainings.type23')  },
    { id: 24, name: translate('users_trainings.type24')  },
    { id: 25, name: translate('users_trainings.type25')  },
    { id: 26, name: translate('users_trainings.type26')  },
    { id: 21, name: translate('users_trainings.type21')  },
    { id: 27, name: translate('users_trainings.type27')  }
  ];


export const choicesTrainingsFormalArt = (translate) => 
  [
    { id: 0, name: translate('users_trainings.formal_art_0')  },
    { id: 1, name: translate('users_trainings.formal_art_1')  }
  ];

export const choicesTrainingsFormal = (translate) => 
  [
    { id: 0, name: translate('users_trainings.formal_0')  },
    { id: 1, name: translate('users_trainings.formal_1')  }
  ];

export const choicesSocialProjects = (translate) => 
  [
    { id: 0, name: translate('users_social_projects.type0')  },
    { id: 1, name: translate('users_social_projects.type1')  },
    { id: 2, name: translate('users_social_projects.type2')  },
    { id: 3, name: translate('users_social_projects.type3')  },
    { id: 4, name: translate('users_social_projects.type4')  },
    { id: 5, name: translate('users_social_projects.type5')  },
    { id: 6, name: translate('users_social_projects.type6')  },
    { id: 7, name: translate('users_social_projects.type7')  },
    { id: 8, name: translate('users_social_projects.type8')  }
  ];
  
export const choicesYears = (translate) => { 
  let currentYear = new Date().getFullYear();
  let i = 0;
  let arrYears = [];
  for (i = 1984; i <= (currentYear+1);i++)
  {
    arrYears.push({ id: i, name: i });
  }
    
  return arrYears;
};

export const choicesShowsType = (translate) => { 
  return ([                        
    { id: 1, name: translate('users_shows.type_1')  },
    { id: 2, name: translate('users_shows.type_2')  },
    { id: 3, name: translate('users_shows.type_3')  },
    { id: 4, name: translate('users_shows.type_4')  }
  ]);
};

export const choicesAdult = (translate) => { 
  return ([                        
    { id: 1, name: translate('users_shows.adult_1')  },
    { id: 2, name: translate('users_shows.adult_2')  },
    { id: 3, name: translate('users_shows.adult_3')  }
  ]);
};

export const choicesRemittancesStatus = (translate) => {
  return ([
    { id: 0, name: translate('remittances.status0') },
    { id: 1, name: translate('remittances.status1') }
  ]);
};


export const choicesRegion = [
  { id: 0, name : ''},
  { id: 1, name : 'L\'Alt Camp'},
  { id: 2, name : 'L\'Alt Empordà'},
  { id: 3, name : 'L\'Alt Penedès'},
  { id: 4, name : 'L\'Alt Urgell'},
  { id: 5, name : 'L\'Alta Ribagorça'},
  { id: 6, name : 'L\'Anoia'},
  { id: 7, name : 'El Bages'},
  { id: 8, name : 'El Baix Camp'},
  { id: 9, name : 'El Baix Ebre'},
  { id: 10, name : 'El Baix Empordà'},
  { id: 11, name : 'El Baix Llobregat'},
  { id: 12, name : 'El Baix Penedès'},
  { id: 13, name : 'El Barcelonès'},
  { id: 14, name : 'El Berguedà'},
  { id: 15, name : 'La Cerdanya'},
  { id: 16, name : 'La Conca de Barberà'},
  { id: 17, name : 'El Garraf'},
  { id: 18, name : 'Les Garrigues'},
  { id: 19, name : 'La Garrotxa'},
  { id: 20, name : 'El Gironès'},
  { id: 21, name : 'El Maresme'},
  { id: 22, name : 'El Montsià'},
  { id: 23, name : 'La Noguera'},
  { id: 24, name : 'Osona'},
  { id: 25, name : 'El Pallars Jussà'},
  { id: 26, name : 'El Pallars Sobirà'},
  { id: 27, name : 'El Pla de l\'Estany'},
  { id: 28, name : 'El Pla d\'Urgell'},
  { id: 29, name : 'El Priorat'},
  { id: 30, name : 'La Ribera d\'Ebre'},
  { id: 31, name : 'El Ripollès'},
  { id: 32, name : 'La Segarra'},
  { id: 33, name : 'El Segrià'},
  { id: 34, name : 'La Selva'},
  { id: 35, name : 'El Solsonès'},
  { id: 36, name : 'El Tarragonès'},
  { id: 37, name : 'La Terra Alta'},
  { id: 38, name : 'L\'Urgell'},
  { id: 39, name : 'La Vall d\'Aran'},
  { id: 40, name : 'El Vallès Occidental'},
  { id: 41, name : 'El Vallès Oriental'},
];
    
export const choicesProvince = [
  { id: 0, name : ''},
  { id: 1, name : 'Àlaba'},
  { id: 2, name : 'Alacant'},
  { id: 3, name : 'Albacete'},
  { id: 4, name : 'Almeria'},
  { id: 5, name : 'Astúries'},
  { id: 6, name : 'Àvila'},
  { id: 7, name : 'Badajoz'},
  { id: 8, name : 'Barcelona'},
  { id: 9, name : 'Biscaia'},
  { id: 10, name : 'Burgos'},
  { id: 11, name : 'Càceres'},
  { id: 12, name : 'Cadis'},
  { id: 13, name : 'Cantàbria'},
  { id: 14, name : 'Castelló'},
  { id: 15, name : 'Ceuta'},
  { id: 16, name : 'Ciudad Real'},
  { id: 17, name : 'Conca'},
  { id: 18, name : 'Còrdova'},
  { id: 19, name : 'Girona'},
  { id: 20, name : 'Granada'},
  { id: 21, name : 'Guadalajara'},
  { id: 22, name : 'Guipúscoa'},
  { id: 23, name : 'Huelva'},
  { id: 24, name : 'Illes Balears'},
  { id: 25, name : 'Jaén'},
  { id: 26, name : 'La Corunya'},
  { id: 27, name : 'La Rioja'},
  { id: 28, name : 'Las Palmas'},
  { id: 29, name : 'Lleida'},
  { id: 30, name : 'Lleó'},
  { id: 31, name : 'Lugo'},
  { id: 32, name : 'Madrid'},
  { id: 33, name : 'Màlaga'},
  { id: 34, name : 'Melilla'},
  { id: 35, name : 'Múrcia'},
  { id: 36, name : 'Navarra'},
  { id: 37, name : 'Osca'},
  { id: 38, name : 'Ourense'},
  { id: 39, name : 'Palència'},
  { id: 40, name : 'Pontevedra'},
  { id: 41, name : 'Salamanca'},
  { id: 42, name : 'Santa Cruz de Tenerife'},
  { id: 43, name : 'Saragossa'},
  { id: 44, name : 'Segòvia'},
  { id: 45, name : 'Sevilla'},
  { id: 46, name : 'Sòria'},
  { id: 47, name : 'Tarragona'},
  { id: 48, name : 'Terol'},
  { id: 49, name : 'Toledo'},
  { id: 50, name : 'València'},
  { id: 51, name : 'Valladolid'},
  { id: 52, name : 'Zamora'},
];

export const choicesCountry = [
  { id: 0, name : '' },
  { id: 1, name : 'Espanya' },
  { id: 2, name : 'França' },
];